<template>
  <v-card elevation="0">
    <div class="pr-5" v-html="user.PrivateSetting.Locked ? Dictionary.GetString('systemshutdownat').replace('@@', user.PrivateSetting.Locked).replace('\n', '<br/>') : ''"></div>
    <v-card-text class=" font-weight-bold black--text text-body-1 pb-0"> {{ Dictionary.GetString('sendmoney') }}</v-card-text> 
    <v-card-text class=" font-weight-bold black--text text-body-1 pb-0">  Kontoname : Liekasse UG</v-card-text> 
     <v-card-text class="font-weight-bold black--text text-body-1 pb-0">IBAN : DE76 1101 0101 5700 7675 98</v-card-text>
     <v-card-text class="font-weight-bold black--text text-body-1 pb-0">BIC : SOBKDEB2XXX</v-card-text>
    <v-card-text v-for="(invoice, index) in user.SevDesk.objects" :key="index">
      <v-card-actions class="pa-2">
        {{ moment(invoice.invoiceDate).format("DD.MM.YYYY") }}
        <span class="px-2">|</span> {{ invoice.header }}
        <v-spacer></v-spacer>
        <h2 class="red--text font-weight-bold">{{ invoice.sumGross }}€</h2>
      </v-card-actions>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>
  
  <script>
import moment from "moment";
import Dictionary from "../../SubClasses/Dictionary";
export default {
  props: ["user"],
  name: "Inovice_Details",
  data() {
    return {
      Dictionary: Dictionary,
      moment: moment,
    };
  },
};
</script>

  