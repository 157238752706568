<template>
  <div class="pa-5 background">
    <Title :title="Dictionary.GetString('addcategories')" />
    <v-row justify="center" align="center" class="mb-1 mt-1">
      <v-col cols="3">
        <v-pagination circle v-model="Categories.Settings.CurrentPage"
          v-bind:class="$vuetify.breakpoint.sm ? '' : 'float-start'" :length="Categories.Settings.Total"
          v-bind:total-visible="$vuetify.breakpoint.sm ? 7 : 12" next-icon="mdi-menu-right" prev-icon="mdi-menu-left"
          @input="GetCategoriesAPI()"></v-pagination>
      </v-col>
      <v-col cols="3">
        <v-layout class="pt-3 pl-2 mr-8" v-bind:class="$vuetify.breakpoint.sm ? 'align-center' : 'align-end'" column>
          <v-flex>
            <v-switch v-model="Categories.List" inset flat class="mt-2" :label="Dictionary.GetString('listpreview')">
            </v-switch>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col cols="5">
        <v-select class="mx-5" :label="Dictionary.GetString('recordsperpage')" v-model="Categories.Settings.RowsPerPage"
          filled rounded hide-details="" dense prepend-inner-icon="mdi-library-shelves"
          :menu-props="{ offsetY: true, closeOnClick: true }" :items="[
      { name: '10', value: 10 },
      { name: '20', value: 20 },
      { name: '30', value: 30 },
      { name: Dictionary.GetString('showall'), value: 1000000000000000 },
    ]" item-text="name" item-value="value" @change="GetCategoriesAPI()"></v-select>
      </v-col>
      <v-col cols="12" sm="1" v-bind:class="$vuetify.breakpoint.sm ? 'text-center' : 'text-end'">
        <v-btn color="info" small v-bind:fab="$vuetify.breakpoint.sm ? false : true"
          @click="[(Category.Type = 'New'), (Category.Open = true), (Category.Item = {}), (Category.Title = Dictionary.GetString('addcategory'))]">
          <v-icon>mdi-pencil-plus-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="Category.Open" width="60vw">
      <v-container class="background pa-3" fluid>
        <v-form ref="InputForm" lazy-validation>
          <v-card flat>
            <v-card-title>
              <v-row align="center" justify="start" class="my-0 mx-1 caption">
                <h1 class="">
                  <v-icon large v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-pencil-plus-outline</v-icon>{{
      Category.Title
    }}
                </h1>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row align="center" justify="center">
                <v-col cols="4">
                  <v-text-field dense single-line class="" hide-details="" filled rounded prepend-inner-icon="mdi-star"
                    :label="Dictionary.GetString('ARvalue')" v-model="Category.Item.name_ar"
                    :rules="[(v) => !!v || Dictionary.GetString('noempty')]">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field hide-details="" dense class="" single-line filled rounded prepend-inner-icon="mdi-star"
                    :label="Dictionary.GetString('DEvalue')" v-model="Category.Item.name_de"
                    :rules="[(v) => !!v || Dictionary.GetString('noempty')]">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field hide-details="" dense class="" single-line filled rounded prepend-inner-icon="mdi-star"
                    :label="Dictionary.GetString('TRvalue')" v-model="Category.Item.name_tr">
                  </v-text-field>
                </v-col>
               
                <v-col cols="6">
                  <v-file-input dense single-line filled rounded hide-details="" :prepend-icon="null"
                    prepend-inner-icon="mdi-upload" clearable :label="Dictionary.GetString('file')"
                    v-model="Category.Item.photoValue" class="Pointer ">
                  </v-file-input>
                </v-col>
                <!--  -->
                <v-col cols="3" class="text-end">
                  <v-img :src="GeneralClasses.LiekasseDomain() + '/' + Category.Item.photo" contain height="100">
                    <template v-slot:placeholder>
                      <v-card height="100%" elevation="3" class="text-center py-2">
                        <v-icon class="background" x-large>mdi-image-off-outline</v-icon>
                      </v-card>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-row align="center" justify="center" class="my-1">
                <v-btn large class="mx-2 mt-3" color="error"
                  @click="[(Category.Type = ''), (Category.Open = false), (Category.Item = {}), (Category.Title = '')]"
                  rounded>
                  <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-close-circle-outline</v-icon>
                  {{ Dictionary.GetString('cancel') }}
                </v-btn>
                <v-btn large class="mx-2 mt-3" color="success" @click="Submit(Category.Item)" rounded>
                  <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-content-save-all-outline
                  </v-icon>
                  {{ Dictionary.GetString('save') }}
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-container>
    </v-dialog>
    <v-data-table v-if="Categories.List" :no-results-text="Dictionary.GetString('noresult')"
      :no-data-text="Dictionary.GetString('noresult')" :headers="Categories.Header" item-key="id" must-sort
      sort-by="id"
      :loading="Categories.Loading" :loading-text="Dictionary.GetString('loading')" hide-default-footer
      :items-per-page="Categories.Settings.RowsPerPage" sort-desc :items="Categories.Items" class="elevation-1 ">
      <template v-slot:[`item.barcode`]="{ item }">
        <v-chip small>{{ item.barcode }}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn x-small fab elevation="0" color="info" class="mx-1 my-1" dark
          @click="[(Category.Type = 'Old'), (Category.Open = true), (Category.Item = item), (Category.Title = Dictionary.GetString('edit'))]">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
        <v-btn x-small fab elevation="0" color="error" class="mx-1 my-1" dark @click="Delete(item)">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-data-iterator v-else item-key="id" must-sort :loading="Categories.Loading" hide-default-footer
      :items-per-page="Categories.Settings.RowsPerPage" :items="Categories.Items">
      <template v-slot:loading>
        <v-row justify="center" align="center" class="mt-1">
          <v-chip justify="center" align="center" color="primary" large>
            <v-progress-circular indeterminate color="white" v-bind:class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
            </v-progress-circular>
            {{ Dictionary.GetString('loading') }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:no-data>
        <v-row justify="center" align="center" class="mt-1">
          <v-chip justify="center" align="center" color="error" large>
            {{ Dictionary.GetString('noresult') }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:no-results>
        <v-row justify="center" align="center" class="mt-1">
          <v-chip justify="center" align="center" color="error" large>
            {{ Dictionary.GetString('noresult') }}
          </v-chip>
        </v-row>
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col cols="12" md="3" v-for="item in props.items" :key="item.id">
            <v-card height="100%" elevation="3" class="pt-2">

              <v-img :src="GeneralClasses.LiekasseDomain() + '/' + item.photo" height="12vh" :aspect-ratio="1 / 1"
                contain class="Pointer">
                <template v-slot:placeholder>
                  <v-card height="100%" elevation="3" class="text-center py-2">
                    <v-icon x-large>mdi-image-off-outline</v-icon>
                  </v-card>
                </template>
              </v-img>
              <v-card-subtitle style="height: 9vh">
                <div class="font-weight-bold mt-2">{{ Dictionary.GetTheRightValue(item.name_ar, item.name_tr,
      item.name_de)
                  }}</div>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer />
                <v-btn x-small fab elevation="0" color="info" class="mx-1 my-1" dark
                  @click="[(Category.Type = 'Old'), (Category.Open = true), (Category.Item = item), (Category.Title = Dictionary.GetString('edit'))]">
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
                <v-btn x-small fab elevation="0" color="error" class="mx-1 my-1" dark @click="Delete(item)">
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from '../../SubClasses/GeneralClasses.js';
import Dictionary from '../../SubClasses/Dictionary.js';

// Components
import Title from '../Shared/Title.vue';

export default {
  props: ['user'],
  name: 'Categories',
  components: { Title },
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },
      Category: {
        Title: '',
        Open: false,
        Item: {},
        Type: '',
      },

      Categories: {
        Loading: true,
        List: true,
        Header: [
          {
            text: '#',
            align: 'start',
            value: 'id',
          },
          {
            text: Dictionary.GetString('title'),
            value: Dictionary.GetTheRightValue('name_ar', 'name_tr', 'name_de'),
          },
          { text: 'Actions', align: 'end', value: 'actions', sortable: false },
        ],
        Items: [],
        Settings: {
          RowsPerPage: 20,
          CurrentPage: 1,
          Total: 0,
        },
      },
    };
  },
  watch: {
    user(Value) {
      this.user = Value;
    },
  },
  methods: {
    Delete(Item) {
      this.$swal
        .fire({
          title: this.Dictionary.GetString('delete'),
          showCancelButton: true,
          confirmButtonText: this.Dictionary.GetString('apply'),
          denyButtonText: this.Dictionary.GetString('cancel'),
          icon: 'error',
        })
        .then((SwalResult) => {
          if (SwalResult.isConfirmed) {
            this.$http
              .delete(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API1()) + Item.id, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
              .then((ResultLiekasse) => {
                this.GetCategoriesAPI();
              })
              .catch((Error) => console.log('Error 0 : ', Error));
          }
        });
    },
    Submit(Item) {
      if (this.$refs.InputForm.validate()) {
        const Data = new FormData();
        Item.photo = Item.photoValue == null ? [] : Item.photoValue;
        Item.sort = 1;
        this.$delete(Item, 'photoValue');
        Object.keys(Item).forEach((Record) => {
          Data.append(Record, Item[Record]);
          console.log('Record : ', Record ,Item);
        });
        var Source = this.Category.Type == 'New' ? this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API1()) : this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API1()) + Item.id;
        this.$http
          .post(Source, Data, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
          .then((Result) => {
            this.$toast.open({
              message: this.Dictionary.GetString('done'),
              type: 'success',
              position: 'bottom',
            });
            this.GetCategoriesAPI();
            this.Category.Type = '';
            this.Category.Open = false;
            this.Category.Item = {};
            this.Category.Title = '';
          })
          .catch((Error) => {
            this.$toast.open({
              message: this.Dictionary.GetString('barcode'),
              type: 'error',
              position: 'bottom',
            });
          });
      }
    },
    GetCategoriesAPI: function () {
      this.Categories.Loading = true;
      this.Categories.Items = [];
      this.$http
        .get(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API1()) + '?page=' + this.Categories.Settings.CurrentPage, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT, this.Categories.Settings.RowsPerPage))
        .then((Result) => {
          this.Categories.Items = Result.data.data;
          this.Categories.Settings.Total = Result.data.meta.last_page;
          this.Categories.Loading = false;
        })
        .catch((Error) => console.log('Error 0 : ', Error));
    },
  },
  mounted() {
    this.GetCategoriesAPI();
  },
};
</script>