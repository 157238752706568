<template>
  <div>
    <v-card color="transparent" width="100%" class="px-1" flat v-if="$vuetify.breakpoint.smAndDown">
      <v-row justify="center" align="center">
        <v-col v-for="CalcItem in [7, 8, 9, 4, 5, 6, 1, 2, 3]" :key="CalcItem" cols="4" class="pt-0">
          <v-btn  @click="AddToText(CalcItem)">{{ CalcItem }}</v-btn>
        </v-col>
        <v-btn  class=" py-2 ml-1 mr-3" @click="AddToText('.')" color="orange" dark>.</v-btn>

        <v-btn class=" py-2 ml-1 mr-3" @click="AddToText(0)">0</v-btn>

        <v-btn  class=" py-2 ml-2 mr-3" @click="AddToText('-')" color="red"  dark><v-icon small>mdi-backspace-outline</v-icon></v-btn>

        <v-col cols="12">
          <v-text-field readonly v-model="Calc.Result"  prepend-inner-icon="mdi-offer" placeholder="0.00" dense clearable filled single-line hide-details=""></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mb-1">
        <v-btn small  class="" v-bind:class="$vuetify.rtl ? 'mr-1 ml-1' : 'ml-1 mr-1'"  color="error" @click="Emit('calcclose')" rounded>
          <v-icon dense small  color="white" v-bind:class="$vuetify.rtl ? 'ml-1' : 'mr-1'">mdi-close-circle-outline</v-icon>
          {{ Dictionary.GetString("cancel") }}
        </v-btn>
        <v-btn small  color="success" @click="Emit('calcvalue', Calc.Result)" rounded>
          <v-icon dense small color="white" v-bind:class="$vuetify.rtl ? 'ml-1' : 'mr-1'">mdi-content-save-all-outline</v-icon>
          {{ Dictionary.GetString("save") }}
        </v-btn>
      </v-row>
    </v-card>
    <v-card color="transparent" width="100%" class="px-5 py-7" flat v-else>
      <v-row justify="center" align="center">
        <v-col v-for="CalcItem in [7, 8, 9, 4, 5, 6, 1, 2, 3]" :key="CalcItem" cols="4">
          <v-btn width="100%" class="headline py-7" @click="AddToText(CalcItem)">{{ CalcItem }}</v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn width="100%" class="headline py-7" @click="AddToText('.')" color="orange" dark>.</v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn width="100%" class="headline py-7" @click="AddToText(0)">0</v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn width="100%" class="headline py-7" @click="AddToText('-')" color="red" dark><v-icon>mdi-backspace-outline</v-icon></v-btn>
        </v-col>
        <v-col cols="12">
          <v-text-field readonly v-model="Calc.Result"
           class="headline" prepend-inner-icon="mdi-offer" placeholder="0.00"
            clearable filled single-line hide-details=""></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mb-1">
        <v-btn large class="mx-2 mt-3" color="error" @click="Emit('calcclose')" rounded>
          <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-close-circle-outline</v-icon>
          {{ Dictionary.GetString("cancel") }}
        </v-btn>
        <v-btn large class="mx-2 mt-3" color="success" @click="Emit('calcvalue', Calc.Result)" rounded>
          <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-content-save-all-outline</v-icon>
          {{ Dictionary.GetString("save") }}
        </v-btn>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../../SubClasses/GeneralClasses.js";
import Dictionary from "../../SubClasses/Dictionary.js";

export default {
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      Calc: {
        Result: "",
      },
    };
  },
  methods: {
    AddToText(CalcItem) {
      if (CalcItem == ".") {
        if (String(this.Calc.Result).includes(".", 0)) {
          // DO Nothing
        } else {
          this.Calc.Result += ".";
        }
      } else if (CalcItem == "-") {
        if (String(this.Calc.Result).length == 0) {
          // DO Nothing
        } else {
          this.Calc.Result = String(this.Calc.Result).substring(0, String(this.Calc.Result).length - 1);
        }
      } else {
        if (String(this.Calc.Result).length == 0 && String(CalcItem) == "0") {
          // DO Nothing
        } else {
          this.Calc.Result += String(CalcItem);
        }
      }
    },
    Emit(Type, Value) {
      this.$emit(Type, Value);
      this.$emit("calcclose");
    },
  },
};
</script>
