<template>
  <div class="pa-5 background">
    <Title :title="$Dictionary.GetString('generalstatistics')" />
    <v-row class="mt-3">
      <v-col cols="12" md="3">

        <v-menu v-model="Statistics.menuFrom" :close-on-content-click="false" :nudge-right="60"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :label="$Dictionary.GetString('fromdate')" autocomplete="new-date-from"
              v-model="Statistics.From" :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"
              prepend-inner-icon="mdi-calendar" filled rounded hide-details="" dense single-line v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="Statistics.From" @input="Statistics.menuFrom = false"></v-date-picker>
        </v-menu>

      </v-col>
      <v-col cols="12" md="3">
        <v-menu ref="menu1" v-model="Statistics.menuTimeFrom" :close-on-content-click="false" :nudge-right="40"
          :return-value.sync="Statistics.Fromtime" transition="scale-transition" min-width="250px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="Statistics.Fromtime" :label="$Dictionary.GetString('fromtime')"
              prepend-inner-icon="mdi-clock-time-four-outline" v-bind="attrs" readonly v-on="on"
              autocomplete="new-time-from" filled rounded hide-details="" dense single-line
              :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"></v-text-field>
          </template>
          <v-time-picker no-title v-if="Statistics.menuTimeFrom" v-model="Statistics.Fromtime"
            @click:minute="$refs.menu1.save(Statistics.Fromtime)"></v-time-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3">
        <v-menu v-model="Statistics.menuTo" :close-on-content-click="false" :nudge-right="60"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field :label="$Dictionary.GetString('todate')" autocomplete="new-date-to" v-model="Statistics.To"
              :rules="[(v) => !!v || $Dictionary.GetString('noempty')]" prepend-inner-icon="mdi-calendar" filled rounded
              hide-details="" dense single-line v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="Statistics.To" @input="Statistics.menuTo = false"></v-date-picker>
        </v-menu>

      </v-col>
      <v-col cols="12" md="3">
        <v-menu ref="menu" v-model="Statistics.menuTimeTo" :close-on-content-click="false" :nudge-right="40"
          :return-value.sync="Statistics.Totime" transition="scale-transition" min-width="250px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="Statistics.Totime" :label="$Dictionary.GetString('totime')"
              prepend-inner-icon="mdi-clock-time-four-outline" v-bind="attrs" readonly v-on="on"
              autocomplete="new-time-from" filled rounded hide-details="" dense single-line
              :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"></v-text-field>
          </template>
          <v-time-picker no-title v-if="Statistics.menuTimeTo" v-model="Statistics.Totime"
            @click:minute="$refs.menu.save(Statistics.Totime)"></v-time-picker>
        </v-menu>


      </v-col>
      <v-col cols="6">
        <v-btn block rounded color="primary" @click="GetStatistics()">
          <v-icon> mdi-update</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn block rounded @click="ExportToExcel" color="success">
          <v-icon>mdi-export-variant</v-icon>&nbsp;Excel
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="my-3" />
    <v-data-table :items="Statistics.Items" :headers="Statistics.Headers" class="overline">
      <template v-slot:[`item.name`]="{ item }">
        {{ $Dictionary.GetTheRightValue(item.product_name_ar, item.product_name_tr, item.product_name_de) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
// Components
import Title from '../Shared/Title.vue';

export default {
  components: { Title },
  data() {
    return {
      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },
      Statistics: {
        From: '',
        To: '',
        Fromtime: '00:00',
        Totime: '23:59',
        menuFrom: false,
        menuTo: false,
        menuTimeTo: false,
        menuTimeFrom: false,
        Type: 1,
        Items: [],
        Headers: [
          {
            text: 'ID',
            align: 'left',
            value: 'product_id',
          },
          {
            text: 'Barcode',
            align: 'left',
            value: 'barcode',
          },
          {
            text: 'Name',
            align: 'left',
            value: 'name',
          },
          {
            text: 'Total Price',
            align: 'left',
            value: 'totalPrice',
          },
          {
            text: 'Total QTY',
            align: 'left',
            value: 'totalQTY',
          },
        ],
      },
    };
  },
  watch: {
    user(Value) {
      this.user = Value;
    },
  },
  methods: {
    ExportToExcel() {
      if (this.Statistics.Items.length > 0) {
        let { json2excel, excel2json } = require('js2excel');
        let data = this.Statistics.Items;
        let Name = 'Stats Data ' + String(this.$Moment(String(new Date())).format('YYYY-MM-DD HH:mm:ss'));
        try {
          json2excel({
            data,
            name: String(Name),
            formateDate: 'yyyy/mm/dd',
          });
        } catch (e) {
          console.error('Export Error');
        }
      }
    },
    GetStatistics() {
      var AxiosConfig = {
        url: this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API7TopProducts()),
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + this.InstallValues.LiekasseAT,
          type: this.Statistics.Type,
          from: this.Statistics.From + ' ' + this.Statistics.Fromtime,
          to: this.Statistics.To + ' ' + this.Statistics.Totime,
        },
      };
      this.$http(AxiosConfig)
        .then((TopProductsResult) => {
          this.Statistics.Items = TopProductsResult.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    var Today = new Date();
    var BeforeCustomDays = new Date(Today.getFullYear(), Today.getMonth(), Today.getDate() - 1);
    this.Statistics.From = this.$Moment(String(BeforeCustomDays)).format('YYYY-MM-DD');
    this.Statistics.To = this.$Moment(String(new Date())).format('YYYY-MM-DD');
    this.GetStatistics();
  },
};
</script>