import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Axios from "axios";

Vue.config.productionTip = false;
Vue.prototype.$http = Axios;

// https://fengyuanchen.github.io/vue-barcode/
import VueBarcode from "@chenfengyuan/vue-barcode";
Vue.component("vue-barcode", VueBarcode);

// https://vuejsfeed.com/blog/vue-js-wrapper-for-awesomeqr-js
import VueQr from "vue-qr";
Vue.component("vue-qr", VueQr);

// https://github.com/ankurk91/vue-toast-notification
import Toast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(Toast);

// https://github.com/avil13/vue-sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

import { format, formatDistance, formatRelative, subDays } from "date-fns";
import Moment from "moment";
import GeneralClasses from "../src/SubClasses/GeneralClasses.js";
import Dictionary from "../src/SubClasses/Dictionary.js";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

Vue.prototype.$Moment = Moment;
Vue.prototype.$DateFns = format;
Vue.prototype.$GeneralClasses = GeneralClasses;
Vue.prototype.$Dictionary = Dictionary;
Vue.prototype.$LSSettings = localStorage.getItem(
  Vue.prototype.$GeneralClasses.LSSettings()
);

if (Vue.prototype.$LSSettings == null) {
  var LS = new Object();
  LS.ATs = {
    LiekasseAT: null,
  };
  LS.Calc = null;
  if (window.innerWidth < 600) LS.Tabe = null;
  LS.Tablet = false;
  LS.POSSystem = {
    UserScreenCounter: 0,
    CartNR: 0,
    MenuValue: 1,
  };
  LS.Checkout = {
    AutoPrintSaveCart: 0,
    Cart: {
      CartNR: -1,
      TableNR: 0,
      ID: 0,
      Items: [],
      DisItems: [],
      Money: {
        ReceivedMoney: 0,
      },
    },
    Categories: {
      ID: 0,
    },
    Products: {
      Switcher: 0,
    },
  };
  LS.UserInfo = {
    Language: "de",
    TSEID: null,
    Dark: false,
    Sections: null,
  };
  
  localStorage.setItem(
    Vue.prototype.$GeneralClasses.LSSettings(),
    JSON.stringify(LS)
  );
  window.location.reload();
} else {
  if (JSON.parse(Vue.prototype.$LSSettings).POSSystem == undefined) {
    try {
      localStorage.clear();
    } finally {
      window.location.reload();
    }
  }
}
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
