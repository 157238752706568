<template>
  <div>
    <v-row class="grey--text" no-gutters>
      <v-icon v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-star-box-multiple-outline</v-icon>
      <h4 class=" font-weight-bold">{{ title }}</h4>
      <v-spacer/>
    </v-row>
    <v-divider class="my-2"/>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from '../../SubClasses/GeneralClasses.js';
import Dictionary from '../../SubClasses/Dictionary.js';

export default {
  name: 'Title',
  props: ['title'],
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),
    };
  },
  watch:
  {
    title(Value)
    {
      this.title = Value;
    }
  },
  methods: {

  },
  created() {},
  mounted() {

  },
};
</script>
