<template>
  <div>
    <v-card color="transparent" width="100%" class="px-5 py-7" flat>
      <v-row justify="center" align="center">
        <v-col v-for="CalcItem in [7, 8, 9, 4, 5, 6, 1, 2, 3]" :key="CalcItem" cols="4">
          <v-btn width="100%" class="headline py-7" @click="AddToText(CalcItem)">{{ CalcItem }}</v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn width="100%" class="headline py-7" @click="AddToText('.')" color="orange" dark>.</v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn width="100%" class="headline py-7" @click="AddToText(0)">0</v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn width="100%" class="headline py-7" @click="AddToText('-')" color="red" dark><v-icon>mdi-backspace-outline</v-icon></v-btn>
        </v-col>
        <v-col cols="12">
          <v-text-field readonly v-model="Calc.Result" class="mx-5 headline" prepend-inner-icon="mdi-offer" placeholder="0.00" clearable rounded filled single-line hide-details=""></v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from '../../SubClasses/GeneralClasses.js';
import Dictionary from '../../SubClasses/Dictionary.js';

export default {
  name: 'Calc',
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      Calc: {
        Result: '',
      },
    };
  },
  methods: {
    AddToText(CalcItem) {
      if (CalcItem == '.') {
        if (String(this.Calc.Result).includes('.', 0)) {
          // DO Nothing
        } else {
          this.Calc.Result += '.';
        }
      } else if (CalcItem == '-') {
        if (String(this.Calc.Result).length == 0) {
          // DO Nothing
        } else {
          this.Calc.Result = String(this.Calc.Result).substring(0, String(this.Calc.Result).length - 1);
        }
      } else {
        if (String(this.Calc.Result).length == 0 && String(CalcItem) == '0') {
          // DO Nothing
        } else {
          this.Calc.Result += String(CalcItem);
        }
      }
      this.LSSettings.Calc = Number(this.Calc.Result);
      localStorage.setItem(this.GeneralClasses.LSSettings(), JSON.stringify(this.LSSettings));
    },
  },
  created() {},
  mounted() {
    this.LSSettings.Calc = null;
    localStorage.setItem(this.GeneralClasses.LSSettings(), JSON.stringify(this.LSSettings));
  },
};
</script>
