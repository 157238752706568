<template>
  <div class="pa-0">
    <v-stepper v-model="Slide">
      <v-stepper-header class="FontCaption">
        <v-stepper-step :complete="Slide > 1" step="1" color="secondary">
          {{ Dictionary.GetString("installshopinfos") }}
        </v-stepper-step>
        <v-stepper-step :complete="Slide > 2" step="2" color="secondary">
          {{ Dictionary.GetString("installimprints") }}
        </v-stepper-step>
        <v-stepper-step step="3" color="secondary">
          {{ Dictionary.GetString("installapply") }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-1 pa-2" flat width="100vw">
            <v-form ref="step1" lazy-validation autocomplete="off">
              <v-row>
                <v-col md="3">
                  <v-select
                    hide-details=""
                    dense
                    single-line
                    :label="Dictionary.GetString('chooselanguage')"
                    v-model="InstallValues.language"
                    filled
                    rounded
                    prepend-inner-icon="mdi-earth"
                    :menu-props="{ offsetY: true, closeOnClick: true }"
                    :items="[
                      { name: 'Deutsch', value: 'de' },
                      { name: 'اللغة العربية', value: 'ar' },
                      { name: 'Türkçe', value: 'tr' },
                    ]"
                    item-text="name"
                    item-value="value"
                    @change="ChangeLanguage"
                  ></v-select>
                </v-col>
                <v-col md="3">
                  <v-select
                    hide-details=""
                    dense
                    single-line
                    :label="Dictionary.GetString('choosetheme')"
                    v-model="InstallValues.theme"
                    filled
                    rounded
                    prepend-inner-icon="mdi-earth"
                    :menu-props="{ offsetY: true, closeOnClick: true }"
                    :items="[
                      { name: Dictionary.GetString('no'), value: false },
                      { name: Dictionary.GetString('yes'), value: true },
                    ]"
                    item-text="name"
                    item-value="value"
                    @change="ChangeTheme"
                  ></v-select>
                </v-col>
                <v-col md="3">
                  <v-select
                    hide-details=""
                    dense
                    single-line
                    :label="Dictionary.GetString('printsystem')"
                    v-model="InstallValues.printsystem"
                    filled
                    rounded
                    prepend-inner-icon="mdi-printer"
                    :menu-props="{ offsetY: true, closeOnClick: true }"
                    :items="[
                      {
                        name: Dictionary.GetString('printsystem1'),
                        value: '0',
                      },
                      {
                        name: Dictionary.GetString('printsystem2'),
                        value: '1',
                      },
                    ]"
                    item-text="name"
                    item-value="value"
                  ></v-select>
                </v-col>
                <v-col md="3">
                  <v-select
                    hide-details=""
                    dense
                    single-line
                    :label="Dictionary.GetString('shoptype')"
                    v-model="InstallValues.shoptype"
                    filled
                    rounded
                    prepend-inner-icon="mdi-earth"
                    :menu-props="{ offsetY: true, closeOnClick: true }"
                    :items="[
                      { name: Dictionary.GetString('shoptype0'), value: '0' },
                      { name: Dictionary.GetString('shoptype1'), value: '1' },
                      { name: Dictionary.GetString('shoptype2'), value: '2' },
                      { name: Dictionary.GetString('shoptype3'), value: '3' },
                      { name: Dictionary.GetString('shoptype4'), value: '4' },
                    ]"
                    item-text="name"
                    item-value="value"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-slider hide-details="" dense single-line v-if="InstallValues.shoptype == 2 || InstallValues.shoptype == 3" class="side3 rounded-pill mb-0 pa-2 pt-3" thumb-size="50" :label="Dictionary.GetString('tablescount')" v-model="InstallValues.tablescount" min="0" max="100" thumb-label="always"></v-slider>
                </v-col>
                <v-col md="3">
                  <v-slider hide-details="" dense single-line class="side3 rounded-pill mb-0 pa-2 pt-3" thumb-size="50" :label="Dictionary.GetString('numberofmenus')" v-model="InstallValues.NumberOfMenus" min="0" max="100" thumb-label="always"></v-slider>
                </v-col>
                <v-col md="3">
                  <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-key-outline" :label="Dictionary.GetString('taxnr')" v-model="InstallValues.TaxNR" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-clock-outline" :label="Dictionary.GetString('shopopentime')" v-model="InstallValues.shopopentime" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-web" :label="Dictionary.GetString('shopname')" v-model="InstallValues.shopname" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-phone" :label="Dictionary.GetString('shoptel')" v-model="InstallValues.shoptel" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-map-marker-outline" :label="Dictionary.GetString('shopstrasse')" v-model="InstallValues.shopstrasse" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-city-variant-outline" :label="Dictionary.GetString('shopplz')" v-model="InstallValues.shopplz" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-facebook" :label="Dictionary.GetString('link')" v-model="InstallValues.facebook"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-instagram" :label="Dictionary.GetString('link')" v-model="InstallValues.instagram"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-file-input hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-image-outline" prepend-icon="" :label="Dictionary.GetString('shopphoto')" v-model="InstallValues.shopphoto" :rules="[(v) => (v && v.size > 0) || Dictionary.GetString('noempty')]"></v-file-input>
                </v-col>
                <v-col cols="12" md="3">
                  <v-file-input hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-picture-in-picture-top-right-outline" prepend-icon="" :label="Dictionary.GetString('shopcover')" v-model="InstallValues.shopcover" :rules="[(v) => (v && v.size > 0) || Dictionary.GetString('noempty')]"></v-file-input>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-key-outline" label="TSE API Key" v-model="InstallValues.TSEKey" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-security" label="TSE API Secret" v-model="InstallValues.TSESecret" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field hide-details="" dense single-line autocomplete="new-username" filled rounded prepend-inner-icon="mdi-web" :label="Dictionary.GetString('username')" v-model="InstallValues.username" :rules="[(v) => !!v || Dictionary.GetString('noempty')]"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field hide-details="" dense single-line autocomplete="new-username" filled rounded prepend-inner-icon="mdi-email" :label="Dictionary.GetString('useremail')" v-model="InstallValues.useremail" :rules="[(v) => !!v || Dictionary.GetString('noempty'), (v) => /.+@.+\..+/.test(v) || Dictionary.GetString('wronginfos')]"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field hide-details="" dense single-line autocomplete="new-password" filled rounded prepend-inner-icon="mdi-star" :append-icon="InstallValues.userpasswordshow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[(v) => !!v || Dictionary.GetString('noempty')]" :type="InstallValues.userpasswordshow ? 'text' : 'password'" :label="Dictionary.GetString('userpassword')" v-model="InstallValues.userpassword" @click:append="InstallValues.userpasswordshow = !InstallValues.userpasswordshow"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field hide-details="" dense single-line autocomplete="new-password" filled rounded prepend-inner-icon="mdi-star" :append-icon="InstallValues.userpasswordconfirmationshow ? 'mdi-eye' : 'mdi-eye-off'" :rules="[(v) => !!v || Dictionary.GetString('noempty'), this.InstallValues.userpassword === this.InstallValues.userpasswordconfirmation || Dictionary.GetString('nomatch')]" :type="InstallValues.userpasswordconfirmationshow ? 'text' : 'password'" :label="Dictionary.GetString('userpasswordconfirmation')" v-model="InstallValues.userpasswordconfirmation" @click:append="InstallValues.userpasswordconfirmationshow = !InstallValues.userpasswordconfirmationshow"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-expansion-panels class="rounded-xl font-weight-bold">
                    <v-expansion-panel key="1">
                      <v-expansion-panel-header>{{ Dictionary.GetString("addorders") }} </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsA" :key="Section.Value">
                            <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel key="2">
                      <v-expansion-panel-header>
                        {{ Dictionary.GetString("addproducts") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsB" :key="Section.Value">
                            <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel key="3">
                      <v-expansion-panel-header>
                        {{ Dictionary.GetString("addproduct") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsC" :key="Section.Value">
                            <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel key="4">
                      <v-expansion-panel-header>
                        {{ InstallValues.shoptype == 1 || InstallValues.shoptype == 3 ? Dictionary.GetString("managecart2") : Dictionary.GetString("managecart1") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsD" :key="Section.Value">
                            <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel key="5">
                      <v-expansion-panel-header>
                        {{ Dictionary.GetString("addcategories") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsE" :key="Section.Value">
                            <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel key="6">
                      <v-expansion-panel-header>
                        {{ Dictionary.GetString("addorders") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsF" :key="Section.Value">
                            <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel key="7">
                      <v-expansion-panel-header>
                        {{ Dictionary.GetString("managebox") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsG" :key="Section.Value">
                            <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel key="8">
                      <v-expansion-panel-header>
                        {{ Dictionary.GetString("generalstatistics") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsH" :key="Section.Value">
                            <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <!-- usermanagement -->
                    <v-expansion-panel key="8">
                      <v-expansion-panel-header>
                        {{ Dictionary.GetString("usermanagement") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" md="4" v-for="Section in InstallValues.Sections.ItemsJ" :key="Section.Value">
                            <v-checkbox v-model="InstallValues.Sections.Checked" :label="Section.Label" :value="Section.Value"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-btn class="mt-6" color="success" rounded dark @click="Step(2)" width="100%">
                {{ Dictionary.GetString("next") }}
              </v-btn>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-5 pa-2" flat width="100vw">
            <v-form ref="step2" lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12">
                  <vue-editor v-model="InstallValues.Imprint" class="mb-5" />
                  <vue-editor v-model="InstallValues.AGB" class="mb-5" />
                  <vue-editor v-model="InstallValues.Privacy" class="mb-5" />
                </v-col>
              </v-row>
              <v-btn color="success" rounded dark @click="Step(3)" width="100%">
                {{ Dictionary.GetString("next") }}
              </v-btn>
            </v-form>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-5 pa-3" flat width="100vw">
            <v-form ref="step3" lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12" class="side3 pa-5 rounded-xl ma-5 font-weight-bold">
                  <v-checkbox v-model="InstallValues.Agreements" :label="Dictionary.GetString('agreements')"></v-checkbox>
                </v-col>
              </v-row>
              <div v-if="InstallDone">
                <div class="text-center">
                  <v-progress-circular :rotate="360" :size="150" :width="20" :value="ProgressValue" color="blue">
                    {{ ProgressValue }}
                  </v-progress-circular>
                </div>
                <v-alert class="text-center mt-5" color="green" dark border="top" transition="scale-transition">
                  <div class="display-1">
                    {{ Dictionary.GetString("thanks") }}
                    <strong>{{ InstallValues.username }}</strong
                    >!
                  </div>
                  <div class="headline">
                    {{ Dictionary.GetString("thanks2") }}
                  </div>
                </v-alert>
              </div>
              <v-btn v-if="!InstallDone" :disabled="!InstallValues.Agreements" color="success" class="mt-5" rounded @click="Step(0)" width="100%">
                {{ Dictionary.GetString("installapply") }}
              </v-btn>
            </v-form>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../SubClasses/GeneralClasses";
import Dictionary from "../SubClasses/Dictionary.js";

// https://www.npmjs.com/package/vue2-editor
import { VueEditor } from "vue2-editor";

export default {
  name: "Install",
  components: { VueEditor },
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      InstallValues: {
        shoptype: "2",
        printsystem: "0",
        userpasswordshow: false,
        userpasswordconfirmationshow: false,
        language: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).UserInfo.Language,
        theme: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).UserInfo.Dark,
        Imprint: "<h1>Impressum:</h1>",
        AGB: "<h1>AGB:</h1>",
        Privacy: "<h1>Datenschutz:</h1>",
        Agreements: false,
        Sections: {
          ItemsA: [],
          ItemsB: [],
          ItemsC: [],
          ItemsD: [],
          ItemsE: [],
          ItemsF: [],
          ItemsG: [],
          ItemsH: [],
          ItemsJ: [],
          Checked: [],
        },
      },

      InstallDone: false,
      Slide: 1,

      ProgressInterval: {},
      ProgressValue: 0,
    };
  },
  beforeDestroy() {
    clearInterval(this.ProgressInterval);
  },
  methods: {
    ChangeLanguage: function () {
      this.LSSettings.UserInfo.Language = this.InstallValues.language;
      localStorage.setItem(this.GeneralClasses.LSSettings(), JSON.stringify(this.LSSettings));
      window.location.replace("/" + this.LSSettings.UserInfo.Language + this.$route.fullPath.substring(3));
    },
    ChangeTheme: function () {
      this.LSSettings.UserInfo.Dark = this.InstallValues.theme;
      localStorage.setItem(this.GeneralClasses.LSSettings(), JSON.stringify(this.LSSettings));
      window.location.replace("/" + this.LSSettings.UserInfo.Language + this.$route.fullPath.substring(3));
    },
    Step: function (StepNum) {
      if (StepNum != 0) {
        if (this.$refs["step" + String(StepNum - 1)].validate()) {
          this.Slide = StepNum;
        }
      } else {
        this.Install();
      }
    },
    Install: function () {
      this.InstallDone = !this.InstallDone;
      this.LSSettings.UserInfo.Sections = window.btoa(JSON.stringify(this.InstallValues.Sections.Checked));
      localStorage.setItem(this.$GeneralClasses.LSSettings(), JSON.stringify(this.LSSettings));
      const InstallSetting = new FormData();
      InstallSetting.append("Name", this.InstallValues.shopname);
      InstallSetting.append("Type", this.InstallValues.shoptype);
      InstallSetting.append("Opentime", this.InstallValues.shopopentime);
      InstallSetting.append("Address", this.InstallValues.shopstrasse);
      InstallSetting.append("Zip", this.InstallValues.shopplz);
      InstallSetting.append("Tel", this.InstallValues.shoptel);
      InstallSetting.append("Facebook", this.InstallValues.facebook);
      InstallSetting.append("Instagram", this.InstallValues.instagram);
      InstallSetting.append("Imprint", this.InstallValues.Imprint);
      InstallSetting.append("AGB", this.InstallValues.AGB);
      InstallSetting.append("Privacy", this.InstallValues.Privacy);
      InstallSetting.append("Photo", this.InstallValues.shopphoto);
      InstallSetting.append("Cover", this.InstallValues.shopcover);
      InstallSetting.append("Tables_count", this.InstallValues.tablescount);
      InstallSetting.append("printing_system", this.InstallValues.printsystem);
      InstallSetting.append("TSEKey", this.InstallValues.TSEKey);
      InstallSetting.append("TSESecret", this.InstallValues.TSESecret);
      InstallSetting.append("TaxNR", this.InstallValues.TaxNR);
      const CreateUser = new FormData();
      CreateUser.append("name", this.InstallValues.username);
      CreateUser.append("email", this.InstallValues.useremail);
      CreateUser.append("password", this.InstallValues.userpassword);
      CreateUser.append("password_confirmation", this.InstallValues.userpasswordconfirmation);
      CreateUser.append("setting", JSON.stringify(JSON.parse(localStorage.getItem(this.GeneralClasses.LSSettings())).UserInfo));
      const CreateSuperAdmin = new FormData();
      CreateSuperAdmin.append("setting", JSON.stringify(JSON.parse(localStorage.getItem(this.GeneralClasses.LSSettings())).UserInfo));
      this.$http
        .post(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.APIInstallBox()), null, this.$GeneralClasses.GetHeaderValue())
        .then((_) => {
          this.$http
            .post(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.APIInstall()), InstallSetting, this.$GeneralClasses.GetHeaderValue())
            .then((_) => {
              this.$http
                .post(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API10()), CreateUser, this.$GeneralClasses.GetHeaderValue())
                .then((_) => {
                  this.$http
                    .post(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API10S()), CreateSuperAdmin, this.$GeneralClasses.GetHeaderValue())
                    .then((_) => {
                      var FullValue = 10000;
                      this.ProgressInterval = setInterval(() => {
                        if (this.ProgressValue === 100) {
                          return (this.ProgressValue = 0);
                        }
                        this.ProgressValue += 10;
                      }, FullValue / 10);
                      setTimeout(() => window.location.replace("/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/cashregister"), FullValue);
                    })
                    .catch((Error) => {
                      console.log("CreateSuperAdmin : " + Error);
                    });
                })
                .catch((Error) => {
                  console.log("CreateUser : " + Error);
                });
            })
            .catch((Error) => {
              console.log("Install : " + Error);
            });
        })
        .catch((Error) => {
          console.log("InstallBox : " + Error);
        });
    },
  },
  mounted() {
    for (let Item = 1; Item <= 13; Item++) {
      this.InstallValues.Sections.ItemsA.push({
        Label: this.Dictionary.GetString("userroleA" + Item),
        Value: "A" + Item,
        Checked: true,
      });
      this.InstallValues.Sections.Checked.push("A" + Item);
    }
    for (let Item = 1; Item <= 8; Item++) {
      this.InstallValues.Sections.ItemsB.push({
        Label: this.Dictionary.GetString("userroleB" + Item),
        Value: "B" + Item,
        Checked: true,
      });
      this.InstallValues.Sections.Checked.push("B" + Item);
    }
    for (let Item = 1; Item <= 5; Item++) {
      this.InstallValues.Sections.ItemsC.push({
        Label: this.Dictionary.GetString("userroleC" + Item),
        Value: "C" + Item,
        Checked: true,
      });
      this.InstallValues.Sections.Checked.push("C" + Item);
    }
    for (let Item = 1; Item <= 5; Item++) {
      this.InstallValues.Sections.ItemsD.push({
        Label: this.Dictionary.GetString("userroleD" + Item),
        Value: "D" + Item,
        Checked: true,
      });
      this.InstallValues.Sections.Checked.push("D" + Item);
    }
    for (let Item = 1; Item <= 1; Item++) {
      this.InstallValues.Sections.ItemsE.push({
        Label: this.Dictionary.GetString("userroleE" + Item),
        Value: "E" + Item,
        Checked: true,
      });
      this.InstallValues.Sections.Checked.push("E" + Item);
    }
    for (let Item = 1; Item <= 5; Item++) {
      this.InstallValues.Sections.ItemsF.push({
        Label: this.Dictionary.GetString("userroleF" + Item),
        Value: "F" + Item,
        Checked: true,
      });
      this.InstallValues.Sections.Checked.push("F" + Item);
    }
    for (let Item = 1; Item <= 4; Item++) {
      this.InstallValues.Sections.ItemsG.push({
        Label: this.Dictionary.GetString("userroleG" + Item),
        Value: "G" + Item,
        Checked: true,
      });
      this.InstallValues.Sections.Checked.push("G" + Item);
    }
    for (let Item = 1; Item <= 1; Item++) {
      this.InstallValues.Sections.ItemsH.push({
        Label: this.Dictionary.GetString("userroleH" + Item),
        Value: "H" + Item,
        Checked: true,
      });
      this.InstallValues.Sections.Checked.push("H" + Item);
    }
    for (let Item = 1; Item <= 1; Item++) {
      this.InstallValues.Sections.ItemsJ.push({
        Label: this.Dictionary.GetString("userroleJ" + Item),
        Value: "J" + Item,
        Checked: true,
      });
      this.InstallValues.Sections.Checked.push("J" + Item);
    }
  },
};
</script>

<style scoped></style>
