<template>
  <div>
    <Title class="mt-2" :title="Dictionary.GetString('Cashiermanagement')" />
    <v-col cols="3" align="">
      <v-card-subtitle> TSE ID : {{ user.PrivateSetting.TSE2ID }}</v-card-subtitle>
    </v-col>
    <v-card class="px-5" elevation="0">
      <v-form ref="marketdata" lazy-validation autocomplete="off">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-web" :label="Dictionary.GetString('shopname')" v-model="user.PublicSetting.Name" @change="ChangeValues('PublicSetting', 'Name', null, user.PublicSetting.Name)"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-phone" :label="Dictionary.GetString('shoptel')" v-model="user.PublicSetting.Tel" @change="ChangeValues('PublicSetting', 'Tel', null, user.PublicSetting.Tel)"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-map-marker-outline" :label="Dictionary.GetString('shopstrasse')" v-model="user.PublicSetting.Address" @change="ChangeValues('PublicSetting', 'Address', null, user.PublicSetting.Address)"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-city-variant-outline" :label="Dictionary.GetString('shopplz')" v-model="user.PublicSetting.Zip" @change="ChangeValues('PublicSetting', 'Zip', null, user.PublicSetting.Zip)"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-facebook" :label="Dictionary.GetString('link')" v-model="user.PublicSetting.Facebook" @change="ChangeValues('PublicSetting', 'Facebook', null, user.PublicSetting.Facebook)"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-instagram" :label="Dictionary.GetString('link')" v-model="user.PublicSetting.Instagram" @change="ChangeValues('PublicSetting', 'Instagram', null, user.PublicSetting.Instagram)"></v-text-field>
          </v-col>

          <v-col cols="12" md="4" align="start">
            <v-card-text class="px-0 pt-0 pb-1 pr-1"> Imprint </v-card-text>
            <v-textarea hide-details="" dense single-line filled rows="4" clearable clear-icon="mdi-close-circle" label="Impressum" v-model="user.PublicSetting.Imprint" @change="ChangeValues('PublicSetting', 'Imprint', null, user.PublicSetting.Imprint)"></v-textarea>
          </v-col>
          <v-col cols="12" md="4" align="start">
            <v-card-text class="px-0 pt-0 pb-1 pr-1"> AGB </v-card-text>
            <v-textarea hide-details="" dense single-line filled rows="4" clearable clear-icon="mdi-close-circle" label="AGB" v-model="user.PublicSetting.AGB" @change="ChangeValues('PublicSetting', 'AGB', null, user.PublicSetting.AGB)"></v-textarea>
          </v-col>
          <v-col cols="12" md="4" align="start">
            <v-card-text class="px-0 pt-0 pb-1 pr-1"> Privacy </v-card-text>
            <v-textarea hide-details="" dense single-line filled rows="4" clearable clear-icon="mdi-close-circle" label="Datenschutz" v-model="user.PublicSetting.Privacy" @change="ChangeValues('PublicSetting', 'Privacy', null, user.PublicSetting.Privacy)"></v-textarea>
          </v-col>
          <v-col md="3">
            <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-clock-outline" :label="Dictionary.GetString('shopopentime')" v-model="user.PublicSetting.Opentime" @change="ChangeValues('PublicSetting', 'Opentime', null, user.PublicSetting.Opentime)"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-numeric" :label="Dictionary.GetString('taxnr')" v-model="user.PrivateSetting.TaxNR" @change="ChangeValues('PrivateSetting', 'TaxNR', null, user.PrivateSetting.TaxNR)"></v-text-field>
          </v-col>
          <v-col md="3">
            <v-file-input hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-image-outline" prepend-icon="" :label="Dictionary.GetString('shopphoto')" v-model="InstallValues.Photo"> </v-file-input>
          </v-col>
          <v-col cols="3">
            <v-file-input hide-details="" dense single-line filled rounded prepend-inner-icon="mdi-picture-in-picture-top-right-outline" prepend-icon="" :label="Dictionary.GetString('shopcover')" v-model="InstallValues.Cover"></v-file-input>
          </v-col>
          <v-col cols="1" align="start">
            <v-card-subtitle class="mr-6">{{ Dictionary.GetString("Storelogo") }}</v-card-subtitle>

            <v-img :src="$GeneralClasses.LiekasseDomain() + '/' + user.PublicSetting.Photo"> </v-img>
          </v-col>
          <v-col cols="1" align="start">
            <v-card-subtitle class="pr-5">{{ Dictionary.GetString("Storecover") }}</v-card-subtitle>

            <v-img :src="$GeneralClasses.LiekasseDomain() + '/' + user.PublicSetting.Cover"> </v-img>
          </v-col>
        </v-row>
      </v-form>
      <v-col class="mt-3" cols="4" justify="center" offset="4">
        <v-btn color="success" rounded dark width="100%" @click="UploadeImage()">
          {{ Dictionary.GetString("save") }}
        </v-btn>
      </v-col>
    </v-card>
    <v-divider class="black mr-1 ml-1" />
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../../SubClasses/GeneralClasses.js";
import Dictionary from "../../SubClasses/Dictionary.js";
import Title from "../Shared/Title.vue";

export default {
  components: { Title },
  name: "System_Management",
  props: ["user"],
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),
      LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),
      PublicSetting: [],
      InstallValues: {},
    };
  },
  watch: {
    user(Value) {
      this.user = Value;
    },
  },
  methods: {
    ChangeValues: function (Setting, Attr1, Attr2, Value) {
      if (Attr2 == null) {
        this.user[Setting][Attr1] = Value;
      } else {
        this.user[Setting][Attr1][Attr2] = Value;
      }
      this.UpdateSettings();
    },
    UploadeImage() {
      var StoreImage = new FormData();
      var PublicSettingsData = new FormData();
      // if ((this.InstallValues.photo && this.InstallValues.Cover) === undefined) {

      // } else {

      if (this.InstallValues.Cover != undefined) {
        StoreImage.append("cover", this.InstallValues.Cover);
        this.$http
          .post(this.GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API9()), StoreImage, this.$GeneralClasses.GetHeaderValue())
          .then((ShopPhotosResult) => {
            this.user.PublicSetting.Cover = ShopPhotosResult.data[0];

            PublicSettingsData.append("options", JSON.stringify(JSON.parse(JSON.stringify(this.user)).PublicSetting));

            this.$http
              .post(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API8UPublic()), PublicSettingsData, this.$GeneralClasses.GetHeaderValue(this.LiekasseAT))
              .then(() => {
                this.$toast.open({
                  message: this.Dictionary.GetString("updateuser"),
                  type: "success",
                  position: "bottom",
                });
                this.InstallValues.Cover = undefined;
              })
              .catch((Error) => console.log("Uploade Public Setting : " + Error));
          })
          .catch((Error) => console.log("Uploade Cover Image : " + Error));
      } else if (this.InstallValues.Photo != undefined) {
        StoreImage.append("photo", this.InstallValues.Photo);
        this.$http
          .post(this.GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API9()), StoreImage, this.$GeneralClasses.GetHeaderValue())
          .then((ShopPhotosResult) => {
            this.user.PublicSetting.Photo = ShopPhotosResult.data[0];

            PublicSettingsData.append("options", JSON.stringify(JSON.parse(JSON.stringify(this.user)).PublicSetting));

            this.$http
              .post(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API8UPublic()), PublicSettingsData, this.$GeneralClasses.GetHeaderValue(this.LiekasseAT))
              .then(() => {
                this.$toast.open({
                  message: this.Dictionary.GetString("updateuser"),
                  type: "success",
                  position: "bottom",
                });
                this.InstallValues.Photo = undefined;
              })
              .catch((Error) => console.log("Uploade Public Setting : " + Error));
          })
          .catch((Error) => console.log("Uploade Photo Image : " + Error));
      } else if ((this.InstallValues.Photo && this.InstallValues.Cover) === undefined) {
        this.$toast.open({
          message: this.Dictionary.GetString("updateuser"),
          type: "success",
          position: "bottom",
        });
      }
    },
    UpdateSettings: function () {
      var PublicSettingsData = new FormData();
      PublicSettingsData.append("options", JSON.stringify(JSON.parse(JSON.stringify(this.user)).PublicSetting));

      var PrivateSettingsData = new FormData();
      PrivateSettingsData.append("options", JSON.stringify(JSON.parse(JSON.stringify(this.user)).PrivateSetting));
      this.$http
        .post(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API8UPublic()), PublicSettingsData, this.GeneralClasses.GetHeaderValue(this.LiekasseAT))
        .then((PublicSettingsResult) => {
          this.$http
            .post(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API8UPrivate()), PrivateSettingsData, this.GeneralClasses.GetHeaderValue(this.LiekasseAT))
            .then((PrivateSettingsResult) => {
              this.$toast.open({
                message: this.Dictionary.GetString("updateuser"),
                type: "success",
                position: "bottom",
              });
              console.log("Done!");
            })
            .catch((Error) => console.log("Error 9 : ", Error));
        })
        .catch((Error) => console.log("Error 8 : ", Error));
    },
  },
};
</script>
