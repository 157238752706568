<template>
  <v-row justify="center" align-content="center" :class="settings.margin">
    <v-chip justify="center" align-content="center" :color="settings.Color" large>
      <v-progress-circular v-show="settings.Loading" indeterminate :color="settings.TColor" v-bind:class="$vuetify.rtl ? 'ml-3' : 'mr-3'"></v-progress-circular>
      {{ settings.Text }}
    </v-chip>
  </v-row>
</template>

<script>
export default {
  name: 'Loading',
  props: ['settings'],
  data() {
    return {};
  },
  watch: {
    settings(Value) {
      this.settings = Value;
    },
  },
  methods: {},
  mounted() {},
};
</script>
