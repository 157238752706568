import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import GeneralClasses from '../SubClasses/GeneralClasses.js';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

var ThemeMode = (localStorage.getItem(GeneralClasses.LSSettings()) == null) ? false : Boolean(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).UserInfo.Dark);
export default new Vuetify({
    theme: {
        dark: ThemeMode,
        themes: {
            light: {
                primary: colors.blue.darken1,
                secondary: colors.grey.darken3,
                accent: colors.deepOrange.darken4,
                error: colors.red.darken1,
                info: colors.blue.darken1,
                success: colors.green.darken1,
                background:colors.shades.white,
                side4:colors.grey.lighten4,                
                side3:colors.grey.lighten3,                  
            },
            dark: {
                primary: colors.blue.darken1,
                secondary: colors.grey.darken3,
                accent: colors.deepOrange.darken4,
                error: colors.red.darken1,
                info: colors.blue.darken1,
                success: colors.green.darken1,      
                background:colors.grey.darken3,   
                side4:colors.grey.darken3,                
                side3:colors.grey.darken2,                            
                FontColor:colors.shades.white,                  
            },            
        },
    },
});