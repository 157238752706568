<template>
  <div class="pa-5 background">
    <v-dialog v-model="Calc.Open" width="60vw">
      <v-container class="background pa-7" fluid>
        <Calc v-if="Calc.Open" />
        <v-row align="center" justify="center" class="mb-1">
          <v-btn large class="mx-2 mt-3" color="error" @click="Calc.Open = false" rounded>
            <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-close-circle-outline</v-icon>
            {{ $Dictionary.GetString("cancel") }}
          </v-btn>
          <v-btn large class="mx-2 mt-3" color="success" @click="CalcSave(Calc.Switcher)" rounded>
            <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-content-save-all-outline</v-icon>
            {{ $Dictionary.GetString("save") }}
          </v-btn>
        </v-row>
      </v-container>
    </v-dialog>
    <Title :title="$Dictionary.GetString('taktmanagement')" />
    <v-row justify="center" align="center" class="mb-1 mt-1">
      <v-col cols="12" md="6">
        <v-pagination circle v-model="Tags.Settings.CurrentPage" v-bind:class="$vuetify.breakpoint.sm ? '' : 'float-start'" :length="Tags.Settings.Total" v-bind:total-visible="$vuetify.breakpoint.sm ? 7 : 12" next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="GetTagsAPI()"></v-pagination>
      </v-col>
      <v-col cols="12" md="5">
        <v-select
          class="mx-4"
          :label="$Dictionary.GetString('recordsperpage')"
          v-model="Tags.Settings.RowsPerPage"
          filled
          rounded
          hide-details=""
          dense
          prepend-inner-icon="mdi-library-shelves"
          :menu-props="{ offsetY: true, closeOnClick: true }"
          :items="[
            { name: '10', value: 10 },
            { name: '20', value: 20 },
            { name: '30', value: 30 },
            { name: $Dictionary.GetString('showall'), value: 1000000000000000 },
          ]"
          item-text="name"
          item-value="value"
          @change="GetTagsAPI()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="1" v-bind:class="$vuetify.breakpoint.sm ? 'text-center' : 'text-end'">
        <v-btn color="info" small v-bind:fab="$vuetify.breakpoint.sm ? false : true" @click="[(Tag.Type = 'New'), (Tag.Open = true), (Tag.Item = {}), (Products.Search.Value = ''), (Tag.Title = $Dictionary.GetString('addtak'))]">
          <v-icon>mdi-pencil-plus-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="Tag.Open" width="50vw">
      <v-container class="background pa-3" fluid>
        <v-form ref="InputForm" lazy-validation>
          <v-card flat>
            <v-card-title>
              <v-row align="center" justify="start" class="my-0 mx-1 caption">
                <h1 class="font-weight-bold"><v-icon large v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-pencil-plus-outline</v-icon>{{ Tag.Title }}</h1>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-row align="center" justify="center">
              <v-col cols="6" class="mt-3">
                <v-text-field hide-details="" filled rounded prepend-inner-icon="mdi-star" class="font-weight-bold text-xl-caption" :label="$Dictionary.GetString('ARvalue')" v-model="Tag.Item.tag_ar" :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"> </v-text-field>
              </v-col>

              <v-col cols="6" class="mt-3">
                <v-text-field hide-details="" filled rounded prepend-inner-icon="mdi-star" class="font-weight-bold text-xl-caption" :label="$Dictionary.GetString('DEvalue')" v-model="Tag.Item.tag_de" :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"> </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field hide-details="" filled rounded prepend-inner-icon="mdi-star" class="font-weight-bold text-xl-caption" :label="$Dictionary.GetString('TRvalue')" v-model="Tag.Item.tag_tr" :rules="[(v) => !!v || $Dictionary.GetString('noempty')]"> </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field filled rounded hide-details="" append-icon="mdi-calculator" @click:append="[(Calc.Open = true), (Calc.Switcher = 0)]" @click="[(Calc.Open = true), (Calc.Switcher = 0)]" prepend-inner-icon="mdi-library-shelves" :label="$Dictionary.GetString('price')" v-model="Tag.Item.price" :rules="[(v) => !!v || $Dictionary.GetString('noempty')]" type="number" class="NoNumberControll font-weight-bold text-xl-caption"> </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-form autocomplete="off" @submit.prevent>
                  <v-autocomplete multiple deletable-chips chips :search-input.sync="Products.Search.Sync" class="font-weight-bold text-xl-caption" :items="Products.Search.Items" item-value="id" hide-details="" :item-text="$Dictionary.GetTheRightValue('name_ar', 'name_tr', 'name_de')" rounded filled single-line prepend-inner-icon="mdi-search-web" :label="$Dictionary.GetString('title')" :no-data-text="$Dictionary.GetString('noresult')" :loading="Products.Search.Loading" v-model="Products.Search.Value"> </v-autocomplete>
                </v-form>
              </v-col>
              <v-col align="start" cols="12">
                <v-chip class="ml-1 mb-1 font-weight-bold text-xl-caption" v-for="item in Tag.Item.products" :key="item.id">
                  {{ $Dictionary.GetTheRightValue(item.name_ar, item.name_tr, item.name_de) }}
                </v-chip>
              </v-col>
            </v-row>
            <v-divider />
            <v-card-actions>
              <v-row align="center" justify="center" class="mt-1" no-gutters>
                <v-row align="center" justify="center" class="mt-1" no-gutters>
                  <v-btn large class="mx-2" color="error" @click="[(Tag.Open = false), (Tag.Item = {})]" rounded>
                    <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-close-circle-outline </v-icon>
                    {{ $Dictionary.GetString("cancel") }}
                  </v-btn>
                  <v-btn large class="mx-2" color="success" @click="[Submit(Products.Search.Value), (Tag.Type = 'New'), (Tag.Open = false)]" rounded>
                    <v-icon color="white" v-bind:class="$vuetify.rtl ? 'ml-5' : 'mr-5'">mdi-content-save-all-outline </v-icon>
                    {{ $Dictionary.GetString("save") }}
                  </v-btn>
                </v-row>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-container>
    </v-dialog>
    <v-data-table :no-results-text="$Dictionary.GetString('noresult')" :no-data-text="$Dictionary.GetString('noresult')" :headers="Tags.Header" item-key="id" must-sort :loading="Tags.Loading" :loading-text="$Dictionary.GetString('loading')" hide-default-footer :items-per-page="Tags.Settings.RowsPerPage" sort-by="id" :items="Tags.Items" class="elevation-1 font-weight-bold text-xl-caption">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn x-small fab elevation="0" color="info" class="mx-1 my-1" dark @click="[(Tag.Type = 'Old'), (Tag.Open = true), (Tag.Item = item), (Tag.Title = $Dictionary.GetString('edit'))]">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
        <v-btn x-small fab elevation="0" color="error" class="mx-1 my-1" dark @click="Delete(item)">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// JS Scripts

// Components
import Title from "../Shared/Title.vue";
import Calc from "../Shared/Calc.vue";

export default {
  props: ["user"],
  name: "Tags",
  components: { Title, Calc },
  data() {
    return {
      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },
      Calc: {
        Open: false,
        Switcher: null,
      },

      Products: {
        Search: {
          Value: "",
          Sync: "",
          Items: [],
          Loading: false,
        },
      },
      Tags: {
        Loading: true,
        Header: [
          {
            text: "#",
            align: "start",
            value: "id",
          },
          {
            text: this.$Dictionary.GetString("title"),
            value: this.$Dictionary.GetTheRightValue("tag_ar", "tag_tr", "tag_de"),
          },
          { text: "Actions", align: "end", value: "actions", sortable: false },
        ],
        Items: [],
        Settings: {
          RowsPerPage: 50,
          CurrentPage: 1,
          Total: 0,
        },
      },
      Tag: {
        Open: false,
        Item: {},
        Type: "",
      },
    };
  },
  watch: {
    "Products.Search.Sync"(Value) {
      if (Value != null) {
        this.Products.Search.Loading = true;
        this.$http
          .get(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API6()) + Value, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
          .then((Result) => {
            this.Products.Search.Items = Result.data.data;
            this.Products.Search.Loading = false;
          })
          .catch((Error) => {
            this.Products.Search.Items = [];
            this.Products.Search.Loading = false;
          });
      }
    },
    user(Value) {
      this.user = Value;
    },
  },
  methods: {
    CalcSave(Object) {
      var LSSettings = JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings()));
      if (LSSettings.Calc != null) {
        switch (Object) {
          case 0:
            this.Tag.Item.price = LSSettings.Calc;
            break;
          default:
        }
      }
      this.Calc.Open = false;
    },
    Delete(Item) {
      this.$swal
        .fire({
          title: this.$Dictionary.GetString("delete"),
          showCancelButton: true,
          confirmButtonText: this.$Dictionary.GetString("apply"),
          denyButtonText: this.$Dictionary.GetString("cancel"),
          icon: "error",
        })
        .then((SwalResult) => {
          if (SwalResult.isConfirmed) {
            this.$http
              .delete(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API24()) + Item.id, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
              .then((ResultLiekasse) => {
                this.GetTagsAPI();
              })
              .catch((Error) => console.log("Error 0 : ", Error));
          }
        });
    },

    Submit(Item) {
      if (this.$refs.InputForm.validate()) {
        const Data = new FormData();
        Data.append("tag_ar", this.Tag.Item.tag_ar);
        Data.append("tag_de", this.Tag.Item.tag_de);
        Data.append("tag_tr", this.Tag.Item.tag_tr);
        Data.append("price", this.Tag.Item.price);
        if (this.Tag.Type == "Old" && this.Products.Search.Value == []) {
          for (var item = 0; item < this.Tag.Item.products.length; item++) {
            Data.append("product_id" + "[" + item + "]", this.Tag.Item.products[item].id);
          }
        } else {
          for (var i = 0; i < Item.length; i++) {
            Data.append("product_id" + "[" + i + "]", Item[i]);
          }
        }

        var Source = this.Tag.Type == "New" ? this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API23()) : this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API23()) + this.Tag.Item.id;
        this.$http
          .post(Source, Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
          .then((Result) => {
            this.GetTagsAPI();
            this.Tag.Open = false;
            this.Tag.Item = {};
            this.Products.Search.Value = "";
            this.Tag.Title = "";
          })
          .catch((Error) => {
            console.log(Error);
          });
      }
    },
    GetTagsAPI: function () {
      this.Tags.Loading = true;
      this.Tags.Items = [];
      this.$http
        .get(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API23()) + "?page=" + this.Tags.Settings.CurrentPage, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT, this.Tags.Settings.RowsPerPage))
        .then((Result) => {
          this.Tags.Items = Result.data.data;
          this.Tags.Settings.Total = Result.data.meta.last_page;
          this.Tags.Loading = false;
        })
        .catch((Error) => {
          console.log("Error 0 : ", Error);
          this.Tags.Loading = false;
        });
    },
  },
  mounted() {
    this.GetTagsAPI();
  },
};
</script>