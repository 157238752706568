<template>
  <div class="pa-5 background">
    <Title :title="$Dictionary.GetString('manageprinters')" />
    <v-data-table :items="Printers.Items" :headers="Printers.Headers"> </v-data-table>
  </div>
</template>

<script>
// Components
import Title from '../Shared/Title.vue';

export default {
  components: { Title },
  data() {
    return {
      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },      
      Printers: {
        Items: [],
        Headers: [
          {
            text: 'API',
            align: 'left',
            value: 'api',
          },
          {
            text: this.$Dictionary.GetString('printer'),
            align: 'left',
            value: 'id',
          },
          {
            text: this.$Dictionary.GetString('printer'),
            align: 'left',
            value: 'printer_id',
          }
        ],
      },
    };
  },
  watch: {
    user(Value) {
      this.user = Value;
    },
  },
  methods: {
    GetPrinters()
    {
      this.$http.get(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API20()), this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((response) => {
          console.log(response);
          this.Printers.Items = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.GetPrinters();
  },
};
</script>