<template>
  <div class="pt-0" v-if="$vuetify.breakpoint.smAndDown">
    <v-dialog v-model="CartSave.Open" width="100vw">
      <v-container class="background pa-4" fluid>
        <PrintsSavedCart v-if="CartSave.Open" :order="CartSave.Item" :user="user" />
      </v-container>
    </v-dialog>

    <Title :title="user.PublicSetting.Type == 2 || user.PublicSetting.Type == 3 ? Dictionary.GetString('managecart2') : Dictionary.GetString('managecart1')" />

    <v-row no-gutters dense>
      <v-col cols="6">
        <v-pagination circle v-bind:class="$vuetify.breakpoint.sm ? '' : 'float-start'" v-model="Carts.Settings.CurrentPage" :length="Carts.Settings.Total" @input="GetCartsAPI()" v-bind:total-visible="$vuetify.breakpoint.sm ? 7 : 12" next-icon="mdi-menu-right" prev-icon="mdi-menu-left"></v-pagination>
      </v-col>
      <v-col cols="6">
        <v-text-field hide-details="" v-show="user.PublicSetting.Type == 2" ref="Barcode" v-model="Carts.Search" clearable prepend-inner-icon="mdi-food" :label="Dictionary.GetString('tablenr') + ' (' + Dictionary.GetString('search') + ')'" rounded filled single-line dense> </v-text-field>
      </v-col>
    </v-row>
    <v-data-table dense v-if="!Order.loading" :no-results-text="Dictionary.GetString('noresult')" :no-data-text="Dictionary.GetString('noresult')" :headers="Carts.HeaderMobile" item-key="id" :loading="Carts.Loading" :loading-text="Dictionary.GetString('loading')" hide-default-footer :items-per-page="Carts.Settings.RowsPerPage" sort-by="id" :sort-desc="true" disable-sort :items="Carts.Items" :search="Carts.Search" class="elevation-3 mt-1">
      <template v-slot:[`item.details`]="{ item }">
        <div>
          <v-chip small v-if="user.PublicSetting.Type == 2"> <v-icon dark v-bind:class="$vuetify.rtl ? 'ml-2' : 'mr-2'">mdi-pen-remove</v-icon> : {{ item.notes != null ? item.notes : "" }} </v-chip>
        </div>
      </template>
      <template v-slot:[`item.created_by`]="{ item }">
        <div>
          <v-chip small class="text-center">{{ item.created_by }}</v-chip>
        </div>
      </template>
      <template v-slot:[`item.brutto`]="{ item }">
        <div>
          <v-chip small class="text-center">{{ item.brutto + item.pledge }}</v-chip>
        </div>
      </template>
      <template v-slot:[`item.table_nr`]="{ item }">
        <div>
          <v-chip small class="text-center">{{ item.table_nr || 0 }}</v-chip>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-btn x-small fab elevation="0" color="info" class="mx-2" @click="[(CartSave.Open = true), (CartSave.Item = item.id)]">
          <v-icon> mdi-printer-settings </v-icon>
        </v-btn> -->
        <v-btn x-small fab elevation="0" outlined class="mx-2" @click="LoadCart(item)" v-show="GeneralClasses.Check('F4')">
          <v-icon> mdi-cart-arrow-up</v-icon>
        </v-btn>
        <v-btn x-small fab elevation="0" color="error" class="mx-2 my-1" dark @click="Delete(item)" v-show="GeneralClasses.Check('F5')">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
  <div class="pa-5" v-else>
    <v-dialog v-model="CartSave.Open" width="50vw">
      <v-container class="background pa-4" fluid>
        <PrintsSavedCart v-if="CartSave.Open" :order="CartSave.Item" :user="user" />
      </v-container>
    </v-dialog>

    <Title :title="user.PublicSetting.Type == 2 || user.PublicSetting.Type == 3 ? Dictionary.GetString('managecart2') : Dictionary.GetString('managecart1')" />

    <v-row class="pt-5">
      <v-col md="6">
        <v-pagination circle v-bind:class="$vuetify.breakpoint.sm ? '' : 'float-start'" v-model="Carts.Settings.CurrentPage" :length="Carts.Settings.Total" @input="GetCartsAPI()" v-bind:total-visible="$vuetify.breakpoint.sm ? 7 : 12" next-icon="mdi-menu-right" prev-icon="mdi-menu-left"></v-pagination>
      </v-col>
      <v-col>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon :disabled="Carts.Loading || Carts.Items.length == 0" size="50" color="warning" v-bind="attrs" v-on="on" @click="Delete_AllOrder()"> mdi-cart-remove </v-icon>
          </template>
          <span>{{ $Dictionary.GetString("deleteall") }} </span>
        </v-tooltip>
      </v-col>

      <v-col md="12">
        <v-text-field hide-details="" v-show="user.PublicSetting.Type == 2" ref="Barcode" v-model="Carts.Search" clearable prepend-inner-icon="mdi-food" :label="Dictionary.GetString('tablenr') + ' (' + Dictionary.GetString('search') + ')'" rounded filled single-line dense> </v-text-field>
      </v-col>
    </v-row>
    <v-data-table v-if="!Order.loading" :no-results-text="Dictionary.GetString('noresult')" :no-data-text="Dictionary.GetString('noresult')" :headers="Carts.HeaderNormal" item-key="id" must-sort :loading="Carts.Loading" :loading-text="Dictionary.GetString('loading')" hide-default-footer :items-per-page="Carts.Settings.RowsPerPage" sort-by="id" :sort-desc="true" disable-sort :items="Carts.Items" :search="Carts.Search" class="elevation-1">
      <template v-slot:[`item.cart_nr`]="{ item }">
        <div class="my-3">
          <v-chip color="warning" outlined class="text-center mx-1 mb-1 Block">{{ item.cart_nr || 0 }}</v-chip>
        </div>
      </template>
      <template v-slot:[`item.details`]="{ item }">
        <div class="my-3">
          <v-chip class="mx-1 mb-1 Block secondary"> <v-icon dark v-bind:class="$vuetify.rtl ? 'ml-2' : 'mr-2'">mdi-clock-outline</v-icon>{{ moment(String(item.updated_at)).format("HH:mm:ss - dddd DD.MM.YYYY") }} </v-chip>
          <v-chip class="mx-1 mb-1 Block warning" v-if="user.PublicSetting.Type == 2"> <v-icon dark v-bind:class="$vuetify.rtl ? 'ml-2' : 'mr-2'">mdi-pen-remove</v-icon>{{ Dictionary.GetString("notes") }} : {{ item.notes != null ? item.notes : " - " }} </v-chip>
          <v-row class="mt-2" no-gutters>
            <v-chip class="mx-1 mb-1" v-for="product in item.order_detals" :key="product.id">
              <v-icon> {{ product.checked ? "mdi-check-bold" : "mdi-close-thick" }} </v-icon>
              &nbsp;&nbsp;
              <span class="warning background--text rounded-lg py-0 px-3">{{ product.QTY }} {{ Dictionary.GetTheRightWeight(product.unit) }}</span>
              &nbsp;&nbsp;
              {{ Dictionary.GetTheRightValue(product.product_name_ar, product.product_name_tr, product.product_name_de) }}
            </v-chip>
          </v-row>
        </div>
      </template>
      <template v-slot:[`item.created_by`]="{ item }">
        <div class="my-3 ml-0">
          <v-chip class="text-center mx-1 mb-1 Block">{{ item.created_by }}</v-chip>
        </div>
      </template>
      <template v-slot:[`item.brutto`]="{ item }">
        <div class="my-3 ml-0">
          <v-chip class="text-center mx-1 mb-1 Block">{{ item.brutto + item.pledge }}</v-chip>
        </div>
      </template>
      <template v-slot:[`item.table_nr`]="{ item }">
        <div class="my-3">
          <v-chip class="text-center mx-1 mb-1 Block">{{ item.table_nr || 0 }}</v-chip>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn x-small fab elevation="0" color="info" class="mx-2 my-1" @click="[(CartSave.Open = true), (CartSave.Item = item.id)]">
          <v-icon> mdi-printer-settings </v-icon>
        </v-btn>
        <v-btn x-small fab elevation="0" outlined class="mx-2 my-1" @click="LoadCart(item)" v-show="GeneralClasses.Check('F4')">
          <v-icon> mdi-cart-arrow-up</v-icon>
        </v-btn>
        <v-btn x-small fab elevation="0" color="error" class="mx-2 my-1" dark @click="Delete(item)" v-show="GeneralClasses.Check('F5')">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <Loading
      v-if="Order.loading"
      :settings="{
        Loading: Order.loading,
        Text: $Dictionary.GetString('Requests_are_being_deleted'),
        Color: 'primary',
        margin: 'mt-15',
      }"
    />
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../../SubClasses/GeneralClasses.js";
import Dictionary from "../../SubClasses/Dictionary.js";

// Components
import Title from "../Shared/Title.vue";
import PrintsSavedCart from "../Shared/Prints/PrintsSavedCart.vue";

import Loading from "../Shared/Loading.vue";
// JS Libraries
import moment from "moment";

export default {
  props: ["user"],
  name: "Carts",
  components: { Title, PrintsSavedCart, Loading },
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),
      moment: moment,

      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },
      Order: {
        IDS: [],
        loading: false,
      },

      CartSave: {
        Open: false,
        Item: 0,
      },

      Carts: {
        TempArrayFor: [],
        Search: "",
        Loading: true,
        ListPreview: false,
        HeaderNormal: [
          {
            text: "#",
            align: "start",
            value: "id",
            filterable: false,
          },
          {
            text: Dictionary.GetString("cartnr"),
            align: "start",
            value: "cart_nr",
            width: "80px",
            filterable: false,
          },
          {
            text: Dictionary.GetString("installgeneralinfos"),
            align: "start",
            value: "details",
            sortable: false,
            filterable: false,
          },
          {
            text: Dictionary.GetString("username"),
            align: "start",
            value: "created_by",
            sortable: true,
            filterable: false,
            width: "100px",
          },
          {
            text: Dictionary.GetString("price2"),
            align: "start",
            value: "brutto",
            sortable: true,
            filterable: false,
            width: "100px",
          },
          {
            text: this.user.PublicSetting.Type == 2 ? Dictionary.GetString("userroleD4") : "",
            align: "start",
            value: this.user.PublicSetting.Type == 2 ? "table_nr" : "",
            filterable: true,
            width: "100px",
          },
          {
            text: "Actions",
            align: "center",
            value: "actions",
            sortable: false,
            width: "200px",
            filterable: false,
          },
        ],
        HeaderMobile: [
          {
            text: Dictionary.GetString("notes"),
            align: "start",
            value: "details",
            sortable: false,
            filterable: false,
          },
          {
            text: Dictionary.GetString("username"),
            align: "start",
            value: "created_by",
            sortable: true,
            filterable: false,
            width: "100px",
          },
          {
            text: Dictionary.GetString("price2"),
            align: "start",
            value: "brutto",
            sortable: true,
            filterable: false,
            width: "100px",
          },
          {
            text: this.user.PublicSetting.Type == 2 ? Dictionary.GetString("userroleD4") : "",
            align: "start",
            value: this.user.PublicSetting.Type == 2 ? "table_nr" : "",
            filterable: true,
            width: "100px",
          },
          {
            align: "center",
            value: "actions",
            sortable: false,
            width: "200px",
            filterable: false,
          },
        ],
        Items: [],
        Settings: {
          RowsPerPage: 75,
          CurrentPage: 1,
          Total: 0,
        },
      },
      SeperatedCart: {
        cart_nr: "",
        order_details: [],
      },
    };
  },
  watch: {
    user(Value) {
      this.user = Value;
    },
  },
  methods: {
    SeperatedOP(Item) {
      console.log(Item);
      console.log(this.Carts.TempArrayFor);
      // Item.cart_nr
      // Sperated Items : this.Carts.TempArrayFor
      // All

      // On Done this.Carts.TempArrayFor = []
    },
    LoadCart: function (Cart) {
      try {
        this.LSSettings.POSSystem.CartNR = JSON.parse(Cart.cart_nr);
        if ((this.user.PublicSetting.Type == 2, this.$vuetify.breakpoint.smAndDown)) {
          this.LSSettings.Tabe = 1;
        }
        localStorage.setItem(GeneralClasses.LSSettings(), JSON.stringify(this.LSSettings));
      } finally {
        window.location.replace("/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/cashregister");
      }
    },
    GetCartsAPI: function () {
      this.Carts.Loading = true;
      this.Carts.Items = [];
      this.$http
        .get(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.APIPOSSystem12()) + "?page=" + this.Carts.Settings.CurrentPage, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT, this.Carts.Settings.RowsPerPage))

        .then((Result) => {
          this.Carts.Items = Result.data.data;
          this.Carts.Settings.Total = Result.data.meta.last_page;
          this.Carts.Loading = false;
          // console.log(this.Carts.Items);
          for (let i = 0; i < Result.data.data.length; i++) {
            this.Order.IDS.push(Result.data.data[i].id);
          }
        })
        .catch((Error) => {
          console.log("Error 0 : ", Error);
          this.Carts.Loading = false;
        });
    },
    Delete_AllOrder() {
      this.$GeneralClasses.ConfirmAlert(this.$Dictionary.GetString("delete_allorders")).then((Result) => {
        if (Result && this.Order.IDS.length > 0) {
          this.Order.loading = true;
          this.$http
            .delete(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.APIPOSSystem28()) + this.Order.IDS, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
            .then((result) => {
              console.log(result.data.message);
              this.Order.loading = false;
              this.Order.loading == false ? window.location.reload() : "";
            })
            .catch((error) => {
              console.log(error);
              this.Order.loading = false;
            });
        }
      });
    },
    Delete: function (Item) {
      this.$http.delete(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.APIPOSSystem16()) + Item.id, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT)).then(() => {
        this.GetCartsAPI();
      });
    },
  },
  mounted() {
    this.GetCartsAPI();
  },
};
</script>