<template>
  <div style="direction: ltr !important">
    <div class="text-center my-5" v-show="!Ready">
      <v-progress-circular :size="150" color="orange" :width="20" indeterminate class="mt-5"></v-progress-circular>
    </div>
    <v-card width="100%" class="text-center pa-5" flat style="overflow: hidden" v-if="Ready">
      <v-btn block color="info" @click="[GeneralClasses.ReloadPage()]" class="mb-3">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
      <div id="PrintArea" class="PrintArea" style="color: black !important; background-color: white !important">
        <table width="100%">
          <tr>
            <td style="text-align: center">
              <img :src="GeneralClasses.LiekasseDomain() + '/' + user.PublicSetting.Photo"
                :alt="user.PublicSetting.Name" style="margin-bottom: 0.25cm; width: 3cm" />
              <div style="font-weight: bold">
                {{ user.PublicSetting.Name }}
              </div>
              <div>{{ user.PublicSetting.Address }}, {{ user.PublicSetting.Zip }}</div>
              <div>Tel : {{ user.PublicSetting.Tel }}</div>
              <div>
                {{ user.PublicSetting.Opentime }}
              </div>
              <br />
              <div v-show="record.created_at" style="font-size: 15pt; font-weight: bold; color: black; ">NR: {{
    Number(record.id)
}}</div>
              <h2>Kassenbericht</h2>
              <div style="font-size: 11pt" v-if="record.fromto != null">{{ record.fromto }}</div>
              <div style="font-size: 11pt" v-if="record.created_at != null">{{
    $Moment(String(record.created_at)).format('HH:mm:ss - dddd DD.MM.YYYY')
}}</div>
            </td>
          </tr>
        </table>
        <hr style="margin-top: 0.15cm; margin-bottom: 0.15cm; opacity: 0.3; border-top: 1px dashed black" />
        <div>
          <table width="100%">
            <tr>
              <td style="text-align: left; width: 50%; font-size: 9pt; font-weight: bold">

                <div>Wechselg</div>
                <div>Ausgabe</div>
                <div>Soll wert</div>
                <div>Ist Wert</div>
                <div>Diffrenz</div>
                <br />
                <div>Karte</div>
                <br />
                <div>Umsatz 19%</div>
                <div>Umsatz 7%</div>
                <div>Umsatz</div>
                <br />
                <div>MWST 19%</div>
                <div>MWST 7%</div>
                <br />
                <div>S_Storno</div>
                <div>Bonn_Abbr</div>
                <br />
                <div>Umtausch 19%</div>
                <div>Umtausch 7%</div>
                <div>Umtausch</div>
                <div>Umtausch MWST 19%</div>
                <div>Umtausch MWST 7%</div>
                <div>Pfand</div>
                <div>Pfand_Tax</div>
              </td>
              <td style="text-align: right; width: 50%; font-size: 9pt">

                <div>{{ Number(record.Wechselg).toFixed(2) }} €</div>
                <div>{{ Number(record.Ausgabe).toFixed(2) }} €</div>
                <div>{{ Number(record.Soll_wert).toFixed(2) }} €</div>
                <div>{{ Number(record.Ist_Wert).toFixed(2) }} €</div>
                <div>{{ Number(record.Diffrenz).toFixed(2) }} €</div>
                <br />
                <div>{{ Number(record.Karte).toFixed(2) }} €</div>
                <br />
                <div>{{ Number(record.Umsatz_19).toFixed(2) }} €</div>
                <div>{{ Number(record.Umsatz_7).toFixed(2) }} €</div>
                <div>{{ Number(record.Umsatz).toFixed(2) }} €</div>
                <br />
                <div>{{ Number(record.MWST_19).toFixed(2) }} €</div>
                <div>{{ Number(record.MWST_7).toFixed(2) }} €</div>
                <br />
                <div>{{ Number(record.S_Storno).toFixed(2) }} €</div>
                <div>{{ Number(record.Bonn_Abbr).toFixed(2) }} €</div>
                <br />
                <div>{{ Number(record.Umtausch_19).toFixed(2) }} €</div>
                <div>{{ Number(record.Umtausch_7).toFixed(2) }} €</div>
                <div>{{ Number(record.Umtausch).toFixed(2) }} €</div>
                <div>{{ Number(record.Umtausch_MWST_19).toFixed(2) }} €</div>
                <div>{{ Number(record.Umtausch_MWST_7).toFixed(2) }} €</div>
                <div>{{ Number(record.pfand).toFixed(2) }} €</div>
                <div>{{ Number(record.pledge_tax).toFixed(2) }} €</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from '../../../SubClasses/GeneralClasses.js';
import Dictionary from '../../../SubClasses/Dictionary.js';

export default {
  name: 'PrintsBox',
  props: ['user', 'record'],
  data () {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      Ready: false,
    };
  },
  methods: {},
  watch: {
    record: function (Value) {
      this.record = Value;
    },
    user: function (Value) {
      this.user = Value;
    },
  },
  mounted () {
    this.Ready = true;
    setTimeout(() => {
      this.GeneralClasses.Print(this.user, 'Invoice', 'PrintArea');
    }, 1000);
  },
};
</script>