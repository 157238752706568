<template>
  <div class="pa-0">
    <v-img :src="GeneralClasses.LiekasseDomain() + '/' + user.PublicSetting.Cover" width="100vw" class="elevation-5"
      max-height="250" />
    <div class="pa-5">
      <v-card class="mx-auto" flat width="60vw">
        <v-form ref="Login" lazy-validation autocomplete="off">
          <v-row>
            <v-col cols="12">
              <v-img :src="GeneralClasses.LiekasseDomain() + '/' + user.PublicSetting.Photo" class="mx-3"
                :position="$vuetify.rtl ? 'right' : 'left'" height="50" contain />
              <v-divider class="my-5 mx-3" />
              <v-text-field autocomplete="new-username" filled rounded prepend-inner-icon="mdi-email"
                :label="Dictionary.GetString('useremail')" v-model="InstallValues.Liekasseuseremail"
                :rules="[(v) => !!v || Dictionary.GetString('noempty'), (v) => /.+@.+\..+/.test(v) || Dictionary.GetString('wronginfos')]"></v-text-field>
              <v-text-field autocomplete="new-password" filled rounded prepend-inner-icon="mdi-star"
                :append-icon="InstallValues.Liekasseuserpasswordshow ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[(v) => !!v || Dictionary.GetString('noempty')]"
                :type="InstallValues.Liekasseuserpasswordshow ? 'text' : 'password'"
                :label="Dictionary.GetString('userpassword')" v-model="InstallValues.Liekasseuserpassword"
                @click:append="InstallValues.Liekasseuserpasswordshow = !InstallValues.Liekasseuserpasswordshow"></v-text-field>
            </v-col>
          </v-row>
          <v-alert v-if="Alerts.Message != ''" color="error" rounded="pill" dark width="60vw">
            {{ Alerts.Message }}
          </v-alert>
          <v-btn color="warning" class="mb-4" v-if="Alerts.Logmeout" rounded dark width="100%" @click="Logmeout()"> {{
            Dictionary.GetString('logmeoutfromall') }} </v-btn>
          <v-btn color="success" rounded dark width="100%" @click="Login()"> {{ Dictionary.GetString('next') }} </v-btn>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from '../../SubClasses/GeneralClasses.js';
import Dictionary from '../../SubClasses/Dictionary.js';

export default {
  name: 'Home',
  props: ['user'],
  data () {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      InstallValues: {
        Liekasseuserpasswordshow: false,
      },

      Alerts: {
        Message: '',
        Logmeout: false,
      },
    };
  },
  watch: {
    user (Value) {
      this.user = Value;
    },
  },
  methods: {
    Logmeout: function () {
      if (this.$refs.Login.validate()) {
        var LiekasseLoginData = { email: this.InstallValues.Liekasseuseremail, password: this.InstallValues.Liekasseuserpassword };
        this.$http
          .post(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API112()), LiekasseLoginData, this.GeneralClasses.GetHeaderValue())
          .then((ResultLiekasseLoginData) => {
            this.Login();
          })
          .catch((Error) => {
            this.Alerts.Message = this.Dictionary.GetString('wronginfos');
          });
      }
    },
    Login: function () {
      if (this.$refs.Login.validate()) {
        var LiekasseLoginData = { email: this.InstallValues.Liekasseuseremail, password: this.InstallValues.Liekasseuserpassword };
        this.$http
          .post(this.GeneralClasses.LiekasseDomain() + window.atob(this.GeneralClasses.API11()), LiekasseLoginData, this.GeneralClasses.GetHeaderValue())
          .then((ResultLiekasseLoginData) => {
            if (ResultLiekasseLoginData.data.access_token != null) {
              this.Alerts.Message = '';
              this.Alerts.Logmeout = false;
              try {
                this.LSSettings.ATs.LiekasseAT = window.btoa(ResultLiekasseLoginData.data.access_token);
                this.LSSettings.UserInfo = JSON.parse(ResultLiekasseLoginData.data.user.setting);
                localStorage.setItem(this.GeneralClasses.LSSettings(), JSON.stringify(this.LSSettings));
                console.log(ResultLiekasseLoginData.data);
              } finally {
                window.location.replace('/' + this.LSSettings.UserInfo.Language + '/main/panel/pages/cashregister');
              }
            } else {
              this.Alerts.Message = this.Dictionary.GetString('wronginfos');
              this.Alerts.Logmeout = false;
            }
          })
          .catch((Error) => {
            if (Error.response.status == 422) {
              this.Alerts.Message = this.Dictionary.GetString('loggedin');
              this.Alerts.Logmeout = true;
            } else {
              this.Alerts.Message = this.Dictionary.GetString('wronginfos');
              this.Alerts.Logmeout = false;
            }
          });
      }
    },
  },
  created () { },
};
</script>
