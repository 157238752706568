<template>
  <div style="direction: ltr !important">
    <div class="text-center mb-1" v-show="!Ready">
      <v-progress-circular :size="150" color="orange" :width="20" indeterminate class="mt-0"></v-progress-circular>
    </div>
    <v-card width="100%" class="text-center" flat style="overflow: hidden" v-if="Ready">
      <v-btn block color="info" @click="[GeneralClasses.ReloadPage()]" class="mb-2">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
      <div id="PrintArea" class="PrintArea" style="color: black !important; background-color: white !important">
        <table width="100%">
          <tr>
            <td style="text-align: center">
              <div style="font-weight: bold">
                {{ user.PublicSetting.Name }}
              </div>
              <div>{{ user.PublicSetting.Address }}, {{ user.PublicSetting.Zip }}</div>
              <div>Tel : {{ user.PublicSetting.Tel }}</div>
              <h2 style="padding-bottom: 0.1cm">Z_Bericht {{ Number(record.id) }}</h2>

              <div style="font-size: 11pt" v-if="record.fromto != null">
                {{ record.fromto }}
              </div>
              <div class="pr-16"> Zeitraum von Uhr Tag Datum  -   bis Uhr Tag  Datum </div>
         
            </td>
          </tr>
        </table>

        <hr style="margin-top: 0.15cm; margin-bottom: 0.15cm; opacity: 0.3; border-top: 1px dashed black" />
        <div>
          <table width="100%">
            <tr>
              <td style="text-align: left; width: 49%; font-size: 8pt; font-weight: bold">
                <div>Z-Bericht Nummer</div>
                <div>Gesamt Tagen</div>
                <div>Bar</div>
                <div>Karte</div>
                <div>Umsatz Ink Mwst 19%</div>
                <div>Umsatz inkl Mwst 7%</div>
                <v-divider></v-divider>
                <br />
                <div>Umsatz</div>
                <v-divider></v-divider>
                <br />
                <div>Mwst 19%</div>
                <div>Mwst 7%</div>
                <div>Bonn_Abbr</div>
                <v-divider></v-divider>
                <br />
                <div>Umtausch</div>
                <div>Umtausch_19</div>
                <v-divider></v-divider>
                <br />
                <div>Umtausch_7</div>
                <div>Umtausch 19% </div>
                <div>Umtausch 7%</div>
                <v-divider></v-divider>
                <br />
             
                <div>Belege Zahlen </div>
                <div>Return Belege Zahlen</div>
                <div> Bohn Abbruch  Zahlen</div>
                <v-divider></v-divider>
                <br />
                <div>Belege Nummer Von</div>
                <div>Belege Nummer Bis</div>
                <v-divider></v-divider>
                <br />
                <div>Datum/Uhr Zeit erste Belege </div>
                <div>Datum/Uhr Zeit Letzte Belege </div>
              </td>
              <td style="text-align: right; width: 49%; font-size: 8pt; font-weight: bold">
                <div>{{ Number(record.id) }}</div>
                <div>{{ Number(record.total_days) }}</div>
                <div>{{ Number(record.bar).toFixed(2) }} €</div>
                <div>{{ Number(record.Karte).toFixed(2) }} €</div>
                <div>{{ Number(record.Umsatz_19).toFixed(2) }} €</div>
                <div>{{ Number(record.Umsatz_7).toFixed(2) }} €</div>
                <v-divider></v-divider>
                <br />

                <div>{{ Number(record.Umsatz).toFixed(2) }} €</div>
                <v-divider></v-divider>
                <br />

                <div>{{ Number(record.MWST_19).toFixed(2) }} €</div>
                <div>{{ Number(record.MWST_7).toFixed(2) }} €</div>
                <div>{{ Number(record.Bonn_Abbr).toFixed(2) }} €</div>
                <v-divider></v-divider>
                <br />

                <div>{{ Number(record.Umtausch).toFixed(2) }} €</div>
                <div>{{ Number(record.Umtausch_19).toFixed(2) }} €</div>
                <v-divider></v-divider>
                <br />

                <div>{{ Number(record.Umtausch_7).toFixed(2) }} €</div>
                <div>{{ Number(record.Umtausch_MWST_19).toFixed(2) }} €</div>
                <div>{{ Number(record.Umtausch_MWST_7).toFixed(2) }} €</div>
                <v-divider></v-divider>
                <br />

                <div>{{ Number(record.total_invoices) }}</div>
                <div>{{ Number(record.total_returns) }}</div>
                <div>{{ Number(record.total_Abbr) }}</div>
                <v-divider></v-divider>
                <br />
                <div>{{ Number(record.last_invoice_nr) }}</div>
                <div>{{ Number(record.first_invoice_nr) }}</div>
                <v-divider></v-divider>
                <br />
                <div>{{ String(record.first_invoice == null ? first_invoice : record.first_invoice) }}</div>
                <div>{{ String(record.last_invoice == null ? last_invoice : record.last_invoice) }}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../../../SubClasses/GeneralClasses.js";
import Dictionary from "../../../SubClasses/Dictionary.js";

export default {
  name: "PrintZZ",
  props: ["user", "record", "first_invoice", "last_invoice"],
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      Ready: false,
    };
  },
  methods: {},
  watch: {
    record: function (Value) {
      this.record = Value;
    },
    user: function (Value) {
      this.user = Value;
    },
  },
  mounted() {
    this.Ready = true;
    setTimeout(() => {
      this.GeneralClasses.Print(this.user, "Invoice", "PrintArea");
    }, 1000);
  },
};
</script>
