import DictionaryStrings from "../SubClasses/DictionaryStrings.json";
import GeneralClasses from "../SubClasses/GeneralClasses.js";

export default {
    GetTheRightValue: function (AR, TR, DE) {
        var Language = JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).UserInfo.Language;
        if (Language == "ar") {
            return AR;
        } else if (Language == "tr") {
            return TR;
        } else if (Language == "de") {
            return DE;
        }
    },
    GetString: function (String, WantedLanguage) {
        if (WantedLanguage == null) {
            var Language = JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).UserInfo.Language;
            try {
                return DictionaryStrings.Strings.find(Word => Word.Value === String)[Language.toUpperCase()]
            } catch {
                return String;
                // console.log("Can't Find The '" + Language.toUpperCase() + "' Word for : " + String);
            }
        }
        else {
            try {
                return DictionaryStrings.Strings.find(Word => Word.Value === String)[(WantedLanguage).toUpperCase()]
            } catch {
                return String;
                // console.log("Can't Find The '" + WantedLanguage.toUpperCase() + "' Word for : " + String);
            }
        }
    },
    GetTheRightWeight: function (WeightType, Lang) {
        if (WeightType == 1) {
            return this.GetString('piece', Lang);
        } else if (WeightType == 2) {
            return this.GetString('kilogram', Lang);
        } else if (WeightType == 3) {
            return this.GetString('milliliters', Lang);
        }
        else {
            return "?";
        }
    },
    GetTheRightWeightBasedOnLang: function (WeightType, WantedLanguage) {
        if (WeightType == 1) {
            return this.GetString('piece', WantedLanguage);
        } else if (WeightType == 2) {
            return this.GetString('kilogram', WantedLanguage);
        } else if (WeightType == 3) {
            return this.GetString('milliliters', WantedLanguage);
        }
        else {
            return "?";
        }
    },
    GetTheRightPaymentMethod: function (PaymentMethod) {
        if (PaymentMethod == 1) {
            return 'BAR';
        } else if (PaymentMethod == 2) {
            return 'EC Karte';
        } else {
            return "?";
        }
    },
};
