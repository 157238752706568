<template>
  <div>
    <v-parallax :src="GeneralClasses.LiekasseDomain() + '/' + user.PublicSetting.Cover">
      <v-col align="end">
        <v-btn color="black" href="/kasse" small rounded>
          <v-icon color="white"> mdi-account-tie </v-icon>
        </v-btn>
      </v-col>
      <v-card-title class="justify-center white--text display-2">
        <span class="rounded-lg px-5 py-3" style="background-color: rgba(0, 0, 0, 0.7)">{{ user.PublicSetting.Name }}</span>
      </v-card-title>
    </v-parallax>

    <!-- <v-divider class="my-0" /> -->
    <v-row justify="center" align="center" class="mb-1 mt-1">
      <v-col cols="12" class="display-1 font-weight-bold text-center">{{ Tab.Title }}</v-col>
    </v-row>
    <transition-group name="slide-left" mode="out-in">
      <div v-if="Tab.NR == 0" key="0">
        <v-row justify="center" align="center" class="mb-1 mt-1" v-if="Products.Settings.Total > 0">
          <!-- <v-col><v-btn>
              login
            </v-btn> </v-col> -->

          <v-col cols="11">
            <v-pagination circle v-model="Products.Settings.CurrentPage" v-bind:class="$vuetify.breakpoint.sm ? '' : ''" :length="Products.Settings.Total" v-bind:total-visible="$vuetify.breakpoint.sm ? 7 : 12" next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="GetProductsAPI"></v-pagination>
          </v-col>
        </v-row>
        <v-select class="mx-8" :label="Dictionary.GetString('recordsperpage')" v-model="Products.Category" filled dense prepend-inner-icon="mdi-library-shelves" :menu-props="{ offsetY: true, closeOnClick: true }" :items="Categories.Items" item-text="Name" item-value="ID" :loading="Categories.Loading" @change="[GetCategoriesAPI()]"></v-select>
        <v-data-iterator class="ma-8" item-key="id" must-sort :sort-desc="true" :loading="Products.Loading" hide-default-footer :items-per-page="Products.Settings.RowsPerPage" sort-by="QTY" :items="Products.Items">
          <template v-slot:loading>
            <v-row justify="center" align="center" class="mt-1">
              <v-chip justify="center" align="center" color="primary" large>
                <v-progress-circular indeterminate color="white" v-bind:class="$vuetify.rtl ? 'ml-3' : 'mr-3'"> </v-progress-circular>
                {{ Dictionary.GetString("loading") }}
              </v-chip>
            </v-row>
          </template>
          <template v-slot:no-data>
            <v-row justify="center" align="center" class="mt-1">
              <v-chip justify="center" align="center" color="error" large>
                {{ Dictionary.GetString("noresult") }}
              </v-chip>
            </v-row>
          </template>
          <template v-slot:no-results>
            <v-row justify="center" align="center" class="mt-1">
              <v-chip justify="center" align="center" color="error" large>
                {{ Dictionary.GetString("noresult") }}
              </v-chip>
            </v-row>
          </template>
          <template v-slot:default="props">
            <v-row>
              <v-col cols="12" md="3" v-for="item in props.items" :key="item.id">
                <v-card height="100%" class="flex d-flex flex-column">
                  <v-img :src="GeneralClasses.LiekasseDomain() + '/' + item.photo" height="100" :aspect-ratio="1 / 1" contain>
                    <template v-slot:placeholder>
                      <v-card height="100%" class="text-center py-2">
                        <v-icon x-large>mdi-image-off-outline</v-icon>
                      </v-card>
                    </template>
                  </v-img>
                  <v-card-subtitle />
                  <div class="headline text-center text-decoration-line-through" v-if="item.price != item.offer_price">
                    {{ Number(item.price).toFixed(2) + "€" }}
                  </div>
                  <v-card-title class="display-1 justify-center" v-text="Number(item.offer_price).toFixed(2) + '€'" />
                  <v-card-title class="rounded-lg ma-3 subtitle-1 font-weight-light justify-center primary white--text" v-text="item.name_de" />
                  <v-divider />
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
        <v-row justify="center" align="center" class="mb-1 mt-1" v-if="Products.Settings.Total > 0">
          <v-col cols="12">
            <v-pagination circle v-model="Products.Settings.CurrentPage" v-bind:class="$vuetify.breakpoint.sm ? '' : ''" :length="Products.Settings.Total" :total-visible="10" next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="GetProductsAPI"></v-pagination>
          </v-col>
        </v-row>
      </div>
      <div v-if="Tab.NR == 1" class="pa-10" v-html="user.PublicSetting.Imprint" key="1"></div>
      <div v-if="Tab.NR == 2" class="pa-10" v-html="user.PublicSetting.AGB" key="2"></div>
      <div v-if="Tab.NR == 3" class="pa-10" v-html="user.PublicSetting.Privacy" key="3"></div>
    </transition-group>
    <v-divider class="my-10" />
    <v-card width="100vw" class="secondary white--text mx-auto pa-10 mt-10">
      <v-card-text class="justify-center">
        <v-img :src="GeneralClasses.LiekasseDomain() + '/' + user.PublicSetting.Photo" class="mx-auto" width="150" contain>
          <template v-slot:placeholder>
            <v-card height="100%" elevation="0" class="text-center py-2">
              <v-icon x-large>mdi-image-off-outline</v-icon>
            </v-card>
          </template>
        </v-img>
      </v-card-text>
      <v-card-subtitle class="text-center white--text">
        <v-btn class="mx-3" x-large color="white" outlined @click="[(Tab.NR = 0), (Tab.Title = 'UNSERE PRODUKTE')]"> UNSERE PRODUKTE</v-btn>
        <v-btn class="mx-3" x-large color="white" outlined @click="[(Tab.NR = 1), (Tab.Title = 'IMPRESSUM')]">IMPRESSUM </v-btn>
        <v-btn class="mx-3" x-large color="white" outlined @click="[(Tab.NR = 2), (Tab.Title = 'AGB')]">AGB</v-btn>
        <v-btn class="mx-3" x-large color="white" outlined @click="[(Tab.NR = 3), (Tab.Title = 'Datenschutz')]"> Datenschutz</v-btn>
      </v-card-subtitle>
      <v-card-title class="justify-center"> {{ user.PublicSetting.Address }}, {{ user.PublicSetting.Zip }} </v-card-title>
      <v-card-title class="justify-center"> Tel : {{ user.PublicSetting.Tel }} </v-card-title>
      <v-card-title class="justify-center">
        {{ user.PublicSetting.Opentime }}
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-btn v-if="user.PublicSetting.Facebook != ''" class="mx-3" large fab color="info" :href="user.PublicSetting.Facebook" target="_blank">
          <v-icon x-large>mdi-facebook</v-icon>
        </v-btn>
        <v-btn v-if="user.PublicSetting.Instagram != ''" class="mx-3" large fab color="error" :href="user.PublicSetting.Instagram" target="_blank">
          <v-icon x-large>mdi-instagram</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../../../SubClasses/GeneralClasses.js";
import Dictionary from "../../../SubClasses/Dictionary.js";

export default {
  name: "Home",
  props: ["user"],
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),
      LiekasseAT: window.atob(JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).ATs.LiekasseAT),

      Tab: {
        Title: "UNSERE PRODUKTE",
        NR: 0,
      },

      Products: {
        Category: 1,
        Loading: true,
        Items: [],
        Settings: {
          group: 1,
          RowsPerPage: 50,
          CurrentPage: 1,
          Total: 0,
        },
      },

      Categories: {
        Loading: true,
        Items: [],
      },
    };
  },
  methods: {
    CheckRout() {
      console.log(this.LiekasseAT.substring(9));
      if (this.LiekasseAT.substring(9) !== "") {
        window.location.replace("/" + this.LSSettings.UserInfo.Language + "/main/panel/pages/cashregister");
      }
    },

    GetCategoriesAPI: function () {
      this.Categories.Loading = true;
      this.Categories.Items = [];
      this.$http
        .get(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API1()))
        .then((Result) => {
          Result.data.data.forEach((Element) => {
            this.Categories.Items.push({
              ID: Element.id,
              Name: Element["name_de"],
            });
          });
          this.Categories.Loading = false;
          this.GetProductsAPI();
        })
        .catch((Error) => {
          console.log(Error);
          this.Categories.Items = [];
          this.Categories.Loading = false;
        });
    },
    GetProductsAPI() {
      console.log(this.Products.Category);
      this.Products.Loading = true;
      this.Products.Items = [];
      this.$http
        .get(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.API4()) + this.Products.Category + "?page=" + this.Products.Settings.CurrentPage, this.$GeneralClasses.GetHeaderValueWithGroup("", this.Products.Settings.RowsPerPage, this.Products.Settings.group))
        .then((Result) => {
          console.log(Result.data.data);
          this.Products.Items = Result.data.data;
          this.Products.Settings.Total = Result.data.meta.last_page;
          this.Products.Loading = false;
        })
        .catch((Error) => {
          console.log(Error);
          this.Products.Loading = false;
        });
    },
  },
  watch: {
    user(Value) {
      this.user = Value;
    },
  },
  mounted() {
    this.GetCategoriesAPI();
    this.CheckRout();
    // console.log(this.LSSettings.UserInfo.Language);
  },
};
</script>
