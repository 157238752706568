<template>
  <div style="direction: ltr !important">
    <div class="text-center my-5" v-show="!Ready">
      <v-progress-circular :size="150" color="orange" :width="20" indeterminate class="mt-5"></v-progress-circular>
    </div>
    <v-card width="100%" class="text-center pa-5" flat style="overflow: hidden" v-if="Ready">
      <v-btn block color="info" @click="[$GeneralClasses.ReloadPage()]" class="mb-3">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
      <div class="DashedBorders pa-2 mb-2" v-if="Order.Item[0].payment_method == 1">
        <v-row>
          <v-col cols="4">
            <div class="font-weight-bold success--text">Gegeben</div>
            {{ Number(Order.Item[0].gived_money).toFixed(2) }}€
          </v-col>
          <v-col cols="4">
            <div class="font-weight-bold info--text">Summe</div>
            {{ (Number(Order.Item[0].brutto) + Number(Order.Item[0].pledge)).toFixed(2) }}
            €
          </v-col>
          <v-col cols="4">
            <div class="font-weight-bold error--text">Rückgeld</div>
            {{ Number(Order.Item[0].returned_money).toFixed(2) }}€
          </v-col>
        </v-row>
      </div>
      <div id="PrintArea" class="PrintArea" style="color: black !important; background-color: white !important">
        <table width="100%" style="font-family: 'Arial' !important">
          <tr>
            <td style="text-align: center">
              <!-- <h4 v-if="String(user.PrivateSetting.TSEKey).substring(0, 4) == 'test'" style="margin-bottom: 0.25cm">[
                TEST TSE ]</h4> -->
              <div v-if="JSON.parse(Order.Item[0].TSE2 == 'TSE Offline')">TSE Offline</div>
              <img :src="GeneralClasses.LiekasseDomain() + '/' + user.PublicSetting.Photo" :alt="user.PublicSetting.Name"
                style="margin-bottom: 0.25cm; width: 3cm" />
              <div style="font-weight: bold">
                {{ user.PublicSetting.Name }}
              </div>
              <div style="font-weight: bold">{{ user.PublicSetting.Address }}, {{ user.PublicSetting.Zip }}</div>
              <div style="font-weight: bold">Tel : {{ user.PublicSetting.Tel }}</div>
              <div style="margin-bottom: 0.25cm; font-weight: bold">
                {{ user.PublicSetting.Opentime }}
              </div>
              <div v-if="user.PublicSetting.Type == 2 && Order.Item[0].cart_nr != -1"
                style="font-weight: bold; font-size: 25pt">
                {{ Order.Item[0].cart_nr }}
              </div>
              <div v-if="Order.Item[0].type == 1 || Order.Item[0].type == 2" style="font-weight: bold; font-size: 11pt">
                Kassenbon NR{{ Order.Item[0].receipt_nr }}</div>
              <div v-if="Order.Item[0].type == 2" style="font-weight: bold; font-size: 16pt">Erstattung Kassenbon NR{{
                Order.Item[0].returned_receipt_nr }}</div>
              <div v-if="Order.Item[0].type == 3" style="font-weight: bold; font-size: 11pt">Bonabbruch Kassenbon NR{{
                Order.Item[0].receipt_nr }}</div>
              <div style="font-size: 11pt; font-weight: bold">
                {{ $Moment(String(Order.Item[0].created_at)).format("DD.MM.YYYY, HH:mm:ss") }}
              </div>
            </td>
          </tr>
        </table>

        <div v-for="(Item, index) in Order.Item[0].order_detals" :key="index">
          <table width="100%" style="font-family: 'Arial' !important">
            <tr>
              <td
                :style="{ 'font-weight': 'bold', 'width': '0.5cm', 'font-size': '10pt', 'padding-bottom': Item.pledge > 0 ? '15px' : ' 1px' }">
                {{ Number(index + 1) }}-</td>
              <td style="text-align: left; font-size: 10pt;">
                <div>
                  {{ Item.product_name_de }}- {{ Item.QTY }} x {{ Number(Item.offer_price) }}
                  {{ Item.unit == 2 ? Number(Item.offer_price) && Dictionary.GetTheRightWeight(Item.unit, "DE") : "" }}
                </div>
                <div v-if="Item.pledge > 0" style="text-align: left; font-size: 8pt;">pfand- {{ Item.QTY }} x {{
                  Item.product_pledge }}</div>

              </td>

              <td style="text-align: right; width: 2cm; font-size: 10pt; font-weight: bold">
                {{ Number(Item.total_price + Item.pledge).toFixed(2) }}
                €

                <div v-if="Item.pledge > 0" style="text-align: right; width: 2cm; font-size: 8pt; font-weight: bold">
                  {{ Number(Item.pledge).toFixed(2) }}
                  €
                </div>
              </td>
            </tr>
          </table>

          <table width="100%">
            <tr align="left">
              <td style="font-weight: 100; font-size: 10pt" v-show="Item.tags.length > 0">
                <div style="margin-left: 8px; font-size: 9pt" v-for="tag in Item.tags" :key="tag.id">{{ tag.tag_de + " - "
                  + tag.price }} €</div>
              </td>
            </tr>
          </table>

          <hr style="margin-top: 0.15cm; margin-bottom: 0.3cm; opacity: 0.3; border-top: 1px dashed black" />
        </div>

        <div>
          <table width="100%" style="font-family: 'Arial' !important">
            <tr>
              <td style="font-weight: bold; text-align: left; width: 50%; font-size: 12pt">Summe</td>
              <td style="font-weight: bold; text-align: right; width: 50%; font-size: 12pt">
                {{ (Number(Order.Item[0].brutto) + Number(Order.Item[0].pledge)).toFixed(2) }}
                €
              </td>
            </tr>
          </table>
        </div>

        <div>
          <table width="100%" style="border-collapse: collapse; font-family: 'Arial' !important">
            <tr>
              <td
                style="padding: 5px; border: 1px dashed black; text-align: left; width: 25%; font-weight: bold; font-size: small">
                <div>Bezahlweise</div>
                {{ Dictionary.GetTheRightPaymentMethod(Order.Item[0].payment_method) }}
              </td>
              <td v-if="Order.Item[0].payment_method == 1"
                style="padding: 5px; border: 1px dashed black; text-align: center; width: 25%; font-weight: bold; font-size: small">
                <div>Gegeben</div>
                {{ Number(Order.Item[0].gived_money).toFixed(2) }}€
              </td>
              <td v-if="Order.Item[0].payment_method == 1"
                style="padding: 5px; border: 1px dashed black; text-align: center; width: 25%; font-weight: bold; font-size: small">
                <div>Rückgeld</div>
                {{ Number(Order.Item[0].returned_money).toFixed(2) }}€
              </td>
              <td
                style="padding: 5px; border: 1px dashed black; text-align: right; width: 25%; font-weight: bold; font-size: small"
                v-if="Number(Order.Item[0].pledge).toFixed(2) > 0">
                <div>Pfand</div>
                {{ Number(Order.Item[0].pledge).toFixed(2) }}€
              </td>
              <!-- edit pfand invoice -->
              <!-- note Translating the table into a barber -->

            </tr>
          </table>

          <table width="100%" style="border-collapse: collapse; font-family: 'Arial' !important">
            <tr>
              <td
                style="padding: 5px; border: 1px dashed black; text-align: left; width: 25%; font-weight: bold; font-size: small">
                <div style="font-weight: bold; font-size: small">Mwst</div>
                <div>19%</div>
                <div>7%</div>
              </td>
              <td
                style="padding: 5px; border: 1px dashed black; text-align: center; width: 25%; font-weight: bold; font-size: small">
                <div style="font-weight: bold; font-size: small">Mwst.</div>
                <div>{{ Number(Order.Item[0].tax_19).toFixed(2) }} €</div>
                <div>{{ Number(Order.Item[0].tax_7).toFixed(2) }} €</div>
              </td>
              <td
                style="padding: 5px; border: 1px dashed black; text-align: center; width: 25%; font-weight: bold; font-size: small">
                <div style="font-weight: bold; font-size: small">Exkl.</div>
                <div>{{ Number(Order.Item[0].netto_19).toFixed(2) }} €</div>
                <div>{{ Number(Order.Item[0].netto_7).toFixed(2) }} €</div>
              </td>
              <td
                style="padding: 5px; border: 1px dashed black; text-align: right; width: 25%; font-weight: bold; font-size: small">
                <div style="font-weight: bold; font-size: small">Inkl.</div>
                <div>{{ Number(Order.Item[0].brutto_19).toFixed(2) }} €</div>
                <div>{{ Number(Order.Item[0].brutto_7).toFixed(2) }} €</div>
              </td>
            </tr>
          </table>
        </div>
        <div style="margin-top: 0.25cm">
          <table width="100%" style="font-family: 'Arial' !important">
            <tr>
              <td style="font-weight: bold; text-align: left; font-size: 10pt">Mwst. -Nr</td>
              <td style="font-weight: bold; text-align: right; font-size: 10pt">
                {{ user.PrivateSetting.TaxNR }}
              </td>
            </tr>
          </table>
        </div>
        <hr style="margin-bottom: 0.15cm; opacity: 0.3; border-top: 1px dashed black" />
        <div>
          <table width="100%" style="font-family: 'Arial' !important">
            <tr>
              <td style="text-align: center">
                <vue-barcode tag="img" v-if="Order.Item[0].type == 1 || Order.Item[0].type == 2" :options="BarcodeOptions"
                  :value="Order.Item[0].receipt_nr"></vue-barcode>
                <vue-barcode tag="img" v-if="Order.Item[0].type == 2" :options="BarcodeOptions"
                  :value="Order.Item[0].returned_receipt_nr"></vue-barcode>
                <vue-barcode tag="img" v-if="Order.Item[0].type == 3" :options="BarcodeOptions"
                  :value="Order.Item[0].receipt_nr"></vue-barcode>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <table width="100%" style="font-family: 'Arial' !important">
            <tr>
              <td style="text-align: left; font-size: 6pt">
                <div style="font-weight: bold; font-size: small">TSE Daten :</div>
                <div style="word-break: break-all; font-weight: bold; font-size: small">
                  TSE Kasse Signature Algorithm :
                  {{ user.PrivateSetting.TSE2Infos.signature_algorithm == "" ?
                    user.PrivateSetting.TSEInfos.signature_algorithm : user.PrivateSetting.TSE2Infos.signature_algorithm }}
                </div>
                <div style="word-break: break-all; font-weight: bold; font-size: small">
                  TSE Zeitformat :
                  {{ user.PrivateSetting.TSE2Infos.signature_timestamp_format == "" ?
                    user.PrivateSetting.TSEInfos.signature_timestamp_format :
                    user.PrivateSetting.TSE2Infos.signature_timestamp_format }}
                </div>
                <div v-if="JSON.parse(Order.Item[0].TSE2 != 'TSE Offline')"
                  style="word-break: break-all; font-weight: bold; font-size: small">
                  TSE Start :
                  {{ $Moment.unix(JSON.parse(Order.Item[0].TSE2 == "" ? Order.Item[0].TSE :
                    Order.Item[0].TSE2).time_start) }}
                </div>
                <div v-if="JSON.parse(Order.Item[0].TSE2 != 'TSE Offline')"
                  style="word-break: break-all; font-weight: bold; font-size: small">
                  TSE Ende :
                  {{ $Moment.unix(JSON.parse(Order.Item[0].TSE2 == "" ? Order.Item[0].TSE : Order.Item[0].TSE2).time_end)
                  }}
                </div>
                <div style="word-break: break-all; font-weight: bold; font-size: small">
                  TSE Kasse Serial Nr. :
                  {{ user.PrivateSetting.TSE2Infos.serial_number == "" ? user.PrivateSetting.TSEInfos.certificate_serial :
                    user.PrivateSetting.TSE2Infos.serial_number }}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div>
          <table width="100%" style="font-family: 'Arial' !important">
            <tr>
              <td style="text-align: left; width: 30%"></td>
              <td style="text-align: center">
                <div>
                  <vue-qr v-if="JSON.parse(Order.Item[0].TSE2 != 'TSE Offline')" :size="300" style="width: 100%"
                    :text="JSON.parse(Order.Item[0].TSE2 == '' ? Order.Item[0].TSE : Order.Item[0].TSE2).qr_code_data"></vue-qr>
                  <vue-qr v-else-if="String(Order.Item[0].TSE2 == 'TSEOffline')" style="width: 100%"
                    text="TSE Offline"></vue-qr>
                </div>
              </td>
              <td style="text-align: right; width: 30%"></td>
            </tr>
          </table>
        </div>
        <div style="text-align: center; font-weight: bold; font-family: 'Arial' !important">Vielen Dank für Ihren Besuch
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../../../SubClasses/GeneralClasses.js";
import Dictionary from "../../../SubClasses/Dictionary.js";

export default {
  name: "PrintsOrder",
  props: ["user", "order", "type"],
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),
      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())).ATs.LiekasseAT),
      },

      Ready: false,
      Order: {
        Item: [],
      },

      returned_money: 0,
      gived_money: 0,
      BarcodeOptions: {
        format: "CODE128",
        height: 50,
        width: 6,
        displayValue: true,
        default: true,
        textPosition: "bottom",
        textAlign: "center",
      },
    };
  },
  methods: {
    GetOrder() {
      var API = "";
      if (this.type == 0) {
        API = window.atob(this.GeneralClasses.API7ByID());
      } else if (this.type == 1) {
        API = window.atob(this.GeneralClasses.API7ByNr1());
      } else if (this.type == 2) {
        API = window.atob(this.GeneralClasses.API7ByNr2());
      } else if (this.type == 3) {
        API = window.atob(this.GeneralClasses.API7ByNr3());
      }
      this.$http
        .get(this.GeneralClasses.LiekasseDomain() + API + this.order, this.GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
        .then((Result) => {
          if (Result != null) {
            if (this.type == 0) {
              this.Order.Item.push(Result.data.data);
            } else {
              this.Order.Item = Result.data.data;
            }
            this.Ready = true;
            setTimeout(() => {
              this.GeneralClasses.Print(this.user, "Invoice", "PrintArea");
            }, 2400);
          }
        })
        .catch((Error) => console.log("Error 0 : ", Error));
    },
  },
  watch: {
    type: function (Value) {
      this.type = Value;
    },
    order: function (Value) {
      this.order = Value;
    },
    user: function (Value) {
      this.user = Value;
    },
  },
  mounted() {
    this.GetOrder();

    // console.log(this.user.PrivateSetting.TSEInfos);
  },
};
</script>
<style scoped>.DashedBorders {
  border-collapse: collapse;
  border: 1px dashed black;
}

.PrintArea {
  letter-spacing: 1.2px;
}</style>
