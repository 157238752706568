<template>
  <v-app>
    <v-card elevation="0" flat>

      <v-row justify="center">

        <v-card class="mt-2 mr-1" height="100vh" width="60%" elevation="0">

          <v-img :src="$GeneralClasses.LiekasseDomain() + '/' + user.PublicSetting.Cover" height="100%"  >

          </v-img>

        </v-card>
        <v-card class="mt-4" flat color="white" height="99vh" width="39%" style="overflow-y: auto; overflow-x: hidden">
          <v-data-iterator item-key="id" style="width: 100%" must-sort :loading="Cart.Loading" hide-default-footer
            :items="Cart.Items.order_detals">
            <template v-slot:loading>
              <Loading :settings="{
                Loading: true,
                Text: $Dictionary.GetString('loading'),
                Color: 'primary',
                margin: 'mt-15'
              }" />
            </template>
            <template v-slot:no-data>
              <Loading :settings="{
                Loading: false,
                Text: $Dictionary.GetString('noresult'),
                Color: 'error',
                margin: 'mt-15'
              }" />
            </template>
            <template v-slot:no-results>
              <Loading :settings="{
                Loading: false,
                Text: $Dictionary.GetString('noresult'),
                Color: 'error',
                margin: 'mt-15'
              }" />
            </template>
            <template v-slot:default="props">
              <v-row align-content="stretch" justify="center">
                <v-col cols="12" v-for="item in props.items" :key="item.id" class="pa-0">
                  <v-card height="100%" class="side4 px-5" rounded="lg" elevation="0">
                    <v-card-actions class="font-weight-bold FontColor--text FontCaption">
                      <h2>{{ $Dictionary.GetTheRightValue(item.product_name_ar, item.product_name_tr,
                          item.product_name_de)
                      }}</h2>
                      <v-spacer />
                      <span class="headline">{{ Number(item.pledge) + Number(item.total_price) }} €</span>
                    </v-card-actions>
                    <v-divider />

                    <v-card-actions v-if="item.pledge > 0">
                      <span class="font-weight-bold">{{ $Dictionary.GetString('pledge') }}</span>
                      <v-spacer />{{ Number(item.pledge) }}€
                    </v-card-actions>
                    <!--  UpdateNotesDetailes -->
                    <v-card-actions>
                      <h2>{{ item.offer_price }}€</h2>
                      <v-spacer class="mx-1" />
                      <h2>x{{ item.QTY }} {{ $Dictionary.GetTheRightWeight(item.unit) }}</h2>
                    </v-card-actions>
                    <!-- <v-card-actions v-if="item.notes != null">
                      <v-chip class="Block">
                        <v-icon left>mdi-pen-remove</v-icon>
                        {{ item.notes }}
                      </v-chip>
                    </v-card-actions> -->
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card>
        <v-footer fixed class="transparent justify-center" height="100">
          <v-card width="85%" color="white" height="70" rounded="pill" elevation="0">
            <v-row align="center" justify="space-around" class="mt-4">
              <v-chip class="text-center transparent  error--text display-1 font-weight-bold">Rückgeld : {{
                  Cart.Items.returned_money
              }}€</v-chip>
              <v-chip v-if="(Number(Cart.Items.brutto) + Number(Cart.Items.pledge)).toFixed(2) > 1"
                class="text-center  transparent black--text  display-1 font-weight-bold">Summe : {{
                    (Number(Cart.Items.brutto) + Number(Cart.Items.pledge)).toFixed(2)
                }}€ </v-chip>
              <v-chip v-if="Cart.Items == ''" class="text-center transparent black--text  display-1 font-weight-bold">
                Summe : 0</v-chip>

              <v-chip class="text-center transparent  success--text  display-1 font-weight-bold">
                Gegeben : {{
                    Cart.Items.gived_money
                }}€</v-chip>
            </v-row>
          </v-card>
        </v-footer>
      </v-row>
    </v-card>
  </v-app>
</template>

<script>
import Loading from './Loading.vue';
export default {
  props: ['user'],
  name: 'Userscreen',
  components: { Loading },
  data() {
    return {
      InstallValues: {
        LiekasseAT: window.atob(JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).ATs.LiekasseAT),
        LSSettings: JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())),
      },
      imageLoding: true,
      Cart: {
        Loading: true,
        CartNR: 0,
        UserScreenCounter: 0,
        Items: [],
      },
    };
  },

  methods: {
    GetCart() {
      this.Cart.CartNR = JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).POSSystem.CartNR;
      this.Cart.UserScreenCounter = JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings())).POSSystem.UserScreenCounter;

    },
    UpdateCart() {
      if (this.Cart.CartNR != 0) {
        const Data = new FormData();
        Data.append('cart_nr', this.Cart.CartNR);
        this.Cart.Loading = true;
        this.$http
          .post(this.$GeneralClasses.LiekasseDomain() + window.atob(this.$GeneralClasses.APIPOSSystem0()), Data, this.$GeneralClasses.GetHeaderValue(this.InstallValues.LiekasseAT))
          .then((Result) => {
            // console.log(Result);
            this.CartInfosUpdate(Result.data.data);
            console.log(this.Cart.UserScreenCounter);
          })
          .catch((Error) => {
            // console.log(Error);
            this.Cart.Loading = false;
          });
      } else {
        this.Cart.Items.brutto = 0;
        this.Cart.Loading = false;
        this.Cart.Items = '';

      }
    },
    CartInfosUpdate(Data) {
      this.Cart.Items = Data;
      this.Cart.Loading = false;
    },
  },

  watch: {
    'Cart.UserScreenCounter'(newValue, oldValue) {
      if (oldValue != newValue) {
        this.UpdateCart();
      }
    },
    order: function (Value) {
      this.order = Value;
    },
  },
  async mounted() {
    this.InstallValues.LSSettings = JSON.parse(localStorage.getItem(this.$GeneralClasses.LSSettings()));
    this.InstallValues.LSSettings.POSSystem.UserScreenCounter = 0;
    localStorage.setItem(this.$GeneralClasses.LSSettings(), JSON.stringify(this.InstallValues.LSSettings));
    await this.GetCart();
    await this.UpdateCart();
    setInterval(() => {
      this.GetCart();
    }, 100);
  },
};
</script>
