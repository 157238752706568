<template>
  <div style="direction: ltr !important">
    <div class="text-center my-0" v-show="!Ready">
      <v-progress-circular :size="150" color="orange" :width="20" indeterminate class="mt-5"></v-progress-circular>
    </div>
    <v-card width="100%" class="text-center pa-0" flat style="overflow: hidden; " v-if="Ready">
      <v-btn block color="info" @click="[GeneralClasses.ReloadPage()]" class="mb-0">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
      <div id="PrintArea" class="PrintArea" style="color: black !important; background-color: white !important">
        <div style=" margin: 0; padding: 0;width: 100%;background-color: white;"
          v-bind:style="GeneralClasses.PrintSize('Product')">

          <div style="padding-top: 0cm;text-align: left; margin: 0; padding: 0;font-size: 1.8rem; font-weight: bold;width: 80%;height: 59px; margin-top: -5px"> {{ product.name_de}}</div>
          <div style="text-align: end; float:right;font-size: 3.4rem; font-weight: bold;width:250px;height: 36px;">
            {{ product.price != product.offer_price ? product.offer_price : product.price }}€
            <span v-show="product.price != product.offer_price"
              style="text-align: right; font-size:1rem; font-weight: bold; text-decoration: line-through">{{
                  product.price
              }}€</span> </div>
          <div style="text-align: left; float: left;width: 50%; height: 19px;margin-top: 0.10cm" class="invoice">
            <span style="float: left; font-size: 1rem;font-weight: bold;">Einheit: {{
                Dictionary.GetTheRightWeightBasedOnLang(product.unit, 'DE')
            }}</span>   </div>
          <div style="width: 30%;text-align: left;height: 2.5cm;">
            <vue-barcode tag="img" :options="BarcodeOptions" :value="product.barcode" style="width:5cm; height:1cm;">
            </vue-barcode></div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
// JS Scripts
import GeneralClasses from "../../../SubClasses/GeneralClasses.js";
import Dictionary from "../../../SubClasses/Dictionary.js";

export default {
  name: "PrintsProduct",
  props: ["user", "product"],
  data() {
    return {
      GeneralClasses: GeneralClasses,
      Dictionary: Dictionary,
      LSSettings: JSON.parse(localStorage.getItem(GeneralClasses.LSSettings())),

      Ready: false,
      BarcodeOptions: {
        format: "CODE128",
        width: 3,
        displayValue: false,
        default: true,
        textPosition: "bottom",
        fontSize: 40,
        fontOptions: "bold",
      },
    };
  },

  methods: {},
  watch: {
    product: function (Value) {
      this.product = Value;
    },
  },
  mounted() {
    this.Ready = true;
    setTimeout(() => {
      this.GeneralClasses.Print(this.user, "Product", "PrintArea");
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>

</style>
