<template>
  <v-container fluid class="ma-0 pa-0">
    <!-- Show only on Mobile -->
    <MobilePOSSystem :user="user" v-if="$vuetify.breakpoint.xs" />
    <NormalPOSSystem :user="user" v-else />
  </v-container>
</template>

<script>
import NormalPOSSystem from "../components/Pages/POSSystem/Normal.vue";
import MobilePOSSystem from "../components/Pages/POSSystem/Mobile.vue";
export default {
  props: ["user"],
  components: { NormalPOSSystem, MobilePOSSystem },
  mounted () { },
};
</script>

<style></style>