<template>
  <div style="direction: ltr !important">
    <div class="text-center my-5" v-show="!Ready">
      <v-progress-circular :size="150" color="orange" :width="20" indeterminate class="mt-5"></v-progress-circular>
    </div>
    <v-card width="100%" class="text-center pa-5" flat style="overflow: hidden;height: 9em;" v-if="Ready">
      <v-btn block color="info" @click="[$GeneralClasses.ReloadPage()]" class="mb-3">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
      <div class="DashedBorders pa-2 mb-2">
        <v-row>
          <v-col cols="4">
            <div class="font-weight-bold success--text">Gegeben</div>
            {{ Number(gived_money).toFixed(2) }}€
          </v-col>
          <v-col cols="4">
            <div class="font-weight-bold info--text">Summe</div>
            {{ (Number(brutto) + Number(pledge)).toFixed(2) }}
            €
          </v-col>
          <v-col cols="4">
            <div class="font-weight-bold error--text">Rückgeld</div>
            {{ Number(returned_money).toFixed(2) }}€
          </v-col>
        </v-row>
      </div>
      <div id="PrintArea" class="PrintArea" style="color: black !important; background-color: white !important">-</div>
    </v-card>
  </div>
</template>

<script>
import printJS from 'print-js'

export default {
  name: "PrinterAsBox",
  props: ["user", "gived_money", "brutto", "pledge", "returned_money"],
  data() {
    return {
      Ready: false,
    };
  },

  methods: {},
  watch: {},
  mounted() {
    this.Ready = true;
    setTimeout(() => {

      printJS({
        printable: 'PrintArea',
        type: 'html',
        targetStyles: ['*'],
        documentTitle: 'Kassenbericht',
      });

      // this.$GeneralClasses.Print(this.user, "PrinterAsBox", "PrintArea");
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
.DashedBorders {

  border-collapse: collapse;
  border: 1px dashed black;
}
</style>