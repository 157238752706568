var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5 background"},[_c('Title',{attrs:{"title":_vm.$Dictionary.GetString('generalstatistics')}}),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":60,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$Dictionary.GetString('fromdate'),"autocomplete":"new-date-from","rules":[function (v) { return !!v || _vm.$Dictionary.GetString('noempty'); }],"prepend-inner-icon":"mdi-calendar","filled":"","rounded":"","hide-details":"","dense":"","single-line":""},model:{value:(_vm.Statistics.From),callback:function ($$v) {_vm.$set(_vm.Statistics, "From", $$v)},expression:"Statistics.From"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.Statistics.menuFrom),callback:function ($$v) {_vm.$set(_vm.Statistics, "menuFrom", $$v)},expression:"Statistics.menuFrom"}},[_c('v-date-picker',{on:{"input":function($event){_vm.Statistics.menuFrom = false}},model:{value:(_vm.Statistics.From),callback:function ($$v) {_vm.$set(_vm.Statistics, "From", $$v)},expression:"Statistics.From"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.Statistics.Fromtime,"transition":"scale-transition","min-width":"250px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.Statistics, "Fromtime", $event)},"update:return-value":function($event){return _vm.$set(_vm.Statistics, "Fromtime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$Dictionary.GetString('fromtime'),"prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","autocomplete":"new-time-from","filled":"","rounded":"","hide-details":"","dense":"","single-line":"","rules":[function (v) { return !!v || _vm.$Dictionary.GetString('noempty'); }]},model:{value:(_vm.Statistics.Fromtime),callback:function ($$v) {_vm.$set(_vm.Statistics, "Fromtime", $$v)},expression:"Statistics.Fromtime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.Statistics.menuTimeFrom),callback:function ($$v) {_vm.$set(_vm.Statistics, "menuTimeFrom", $$v)},expression:"Statistics.menuTimeFrom"}},[(_vm.Statistics.menuTimeFrom)?_c('v-time-picker',{attrs:{"no-title":""},on:{"click:minute":function($event){return _vm.$refs.menu1.save(_vm.Statistics.Fromtime)}},model:{value:(_vm.Statistics.Fromtime),callback:function ($$v) {_vm.$set(_vm.Statistics, "Fromtime", $$v)},expression:"Statistics.Fromtime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":60,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$Dictionary.GetString('todate'),"autocomplete":"new-date-to","rules":[function (v) { return !!v || _vm.$Dictionary.GetString('noempty'); }],"prepend-inner-icon":"mdi-calendar","filled":"","rounded":"","hide-details":"","dense":"","single-line":""},model:{value:(_vm.Statistics.To),callback:function ($$v) {_vm.$set(_vm.Statistics, "To", $$v)},expression:"Statistics.To"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.Statistics.menuTo),callback:function ($$v) {_vm.$set(_vm.Statistics, "menuTo", $$v)},expression:"Statistics.menuTo"}},[_c('v-date-picker',{on:{"input":function($event){_vm.Statistics.menuTo = false}},model:{value:(_vm.Statistics.To),callback:function ($$v) {_vm.$set(_vm.Statistics, "To", $$v)},expression:"Statistics.To"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.Statistics.Totime,"transition":"scale-transition","min-width":"250px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.Statistics, "Totime", $event)},"update:return-value":function($event){return _vm.$set(_vm.Statistics, "Totime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$Dictionary.GetString('totime'),"prepend-inner-icon":"mdi-clock-time-four-outline","readonly":"","autocomplete":"new-time-from","filled":"","rounded":"","hide-details":"","dense":"","single-line":"","rules":[function (v) { return !!v || _vm.$Dictionary.GetString('noempty'); }]},model:{value:(_vm.Statistics.Totime),callback:function ($$v) {_vm.$set(_vm.Statistics, "Totime", $$v)},expression:"Statistics.Totime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.Statistics.menuTimeTo),callback:function ($$v) {_vm.$set(_vm.Statistics, "menuTimeTo", $$v)},expression:"Statistics.menuTimeTo"}},[(_vm.Statistics.menuTimeTo)?_c('v-time-picker',{attrs:{"no-title":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.Statistics.Totime)}},model:{value:(_vm.Statistics.Totime),callback:function ($$v) {_vm.$set(_vm.Statistics, "Totime", $$v)},expression:"Statistics.Totime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","rounded":"","color":"primary"},on:{"click":function($event){return _vm.GetStatistics()}}},[_c('v-icon',[_vm._v(" mdi-update")])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","rounded":"","color":"success"},on:{"click":_vm.ExportToExcel}},[_c('v-icon',[_vm._v("mdi-export-variant")]),_vm._v(" Excel ")],1)],1)],1),_c('v-divider',{staticClass:"my-3"}),_c('v-data-table',{staticClass:"overline",attrs:{"items":_vm.Statistics.Items,"headers":_vm.Statistics.Headers},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$Dictionary.GetTheRightValue(item.product_name_ar, item.product_name_tr, item.product_name_de))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }